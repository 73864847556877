import React from 'react';
import styles from './Indicator.module.scss';

export interface IIndicator {
    value?: number;
    className?: string;
    fontSize?: string;
    type?: string;
}

export const Indicator = ({
    value = 0,
    className,
    type = 'percent',
    fontSize = '12px',
}: IIndicator) => {
    const lineHeight = fontSize === '10px' ? '10px' : '';

    const valueAbs = Math.abs(value);

    return (
        <div
            className={`${styles[value > 0 ? 'indicator-positive' : 'indicator-negative']} ${className}`}
        >
            <div
                className={styles['indicator-value']}
                style={{ fontSize, lineHeight }}
            >
                {value > 0 ? <span>+</span> : null}
                {value < 0 ? <span>-</span> : null}
                <span>{valueAbs?.toFixed(1).replace('.', ',')}</span>
                <span>{type === 'percent' ? '%' : 'pp'}</span>
            </div>
        </div>
    );
};
