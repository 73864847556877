import React from "react";
import { useFormContext } from "react-hook-form";
import { ButtonPrimary } from "../../../../../../components";
import styles from "../SetupFarma.module.scss";
import { SetupFarmaFormValues, useFarma } from "../hooks/useFarma";

export const SetupFarmaFooter = () => {
    const { handleSubmit } = useFormContext<SetupFarmaFormValues>();
    const {updateConfigFarma} = useFarma();

    const onSubmit = handleSubmit((data) => {
        updateConfigFarma(data);
    });

    return (
        <div className={styles.footer}>
            <ButtonPrimary type="reset" theme="ghost">
                cancelar
            </ButtonPrimary>
            <ButtonPrimary type="button" onClick={onSubmit}>
                salvar
            </ButtonPrimary>
        </div>
    );
};
