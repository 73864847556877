import React from 'react';
import { Button, Modal } from 'rsuite';

import styles from './ModalConfirmAction.module.scss';

const { Header, Title, Body, Footer } = Modal;

type ModalConfirmActionProps = {
    show?: boolean;
    title?: string;
    description?: string;
    onHide?: () => void;
    onClickAction?: () => void;
};

export const ModalConfirmAction = ({
    show,
    title = 'Deletar cenário',
    description = 'Você está deletando um cenário. Confirma essa ação?',
    onHide,
    onClickAction,
}: ModalConfirmActionProps) => {
    return (
        <Modal className={styles.modal} show={show} onHide={onHide}>
            <Header onHide={onHide}>
                <Title>{title}</Title>
            </Header>
            <Body>
                <p>{description}</p>
            </Body>
            <Footer>
                <Button
                    className={styles['btn-cancel']}
                    appearance="ghost"
                    onClick={onHide}
                >
                    Cancelar
                </Button>
                <Button
                    id="btn-save-scenario"
                    className={styles['btn-main']}
                    appearance="primary"
                    onClick={onClickAction}
                >
                    Confirmar
                </Button>
            </Footer>
        </Modal>
    );
};
