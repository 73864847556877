import { formatNumbersToPtBr } from '../../../../../../utils/FormatNumbers';

export const generateElasticity = (elasticityValue: number | null) => {
    if (!elasticityValue) {
        return {
            classification: 'Sem dados',
            description: 'Sem dados',
        };
    }

    const elasticity = Math.abs(elasticityValue);

    switch (true) {
        case elasticity >= 0 && elasticity <= 1:
            return {
                classification: 'Inelástico',
                description:
                    'Variações de preço não impactam nas vendas do produto',
            };
        case elasticity > 1 && elasticity <= 1.5:
            return {
                classification: 'Pouco Elástico',
                description:
                    'Variações de preços podem impactar nas vendas do produto',
            };
        case elasticity > 1.5 && elasticity <= 3:
            return {
                classification: 'Elástico',
                description:
                    'Pequenas variações de preço impactam nas vendas do produto',
            };
        case elasticity > 3:
            return {
                classification: 'Muito elástico',
                description:
                    'Pequenas variações de preço, causam grande impacto nas vendas do produto',
            };
        default:
            return {
                classification: 'Inelástico',
                description:
                    'Variações de preço não impactam nas vendas do produto',
            };
    }
};

export const generateElasticityValue = (value: number | null) => {
    if (!value) {
        return '--';
    }

    const absValue = Math.abs(value) > 4 ? 4 : Math.abs(value);

    const valueFormat = formatNumbersToPtBr(Math.abs(absValue), 'decimal');

    return valueFormat;
};
