import React, { memo, useMemo } from 'react';
import { Whisper } from 'rsuite';
import {
    CurrencyInput,
    InputAddon,
    InputGroup,
} from '../../../../../../components';
import { InputCurrencyProps } from '../../../../../../components/InputCurrency/InputCurrency';
import { IPAMaths } from '../../../../../../lib';
import { VariationBoxIndicator } from '../../../../Negociacoes/pages/NegociacaoFornecedor/components';
import { MarginLimitInfo } from '../../MarginLimitInfo';
import { BaseCell } from '../BaseCell';
import { BaseCellWithUpdateCampoProps } from '../BaseCell/BaseCell';

type Skin = InputCurrencyProps['skin'];

type MargemCellProps = BaseCellWithUpdateCampoProps;

const MargemCell = ({
    rowData,
    index,
    dataKey,
    handleUpdateCampo,
    handleBlur,
    ...props
}: MargemCellProps) => {
    const {
        maxMargin,
        minMargin,
        novaMargem,
        novaCompetitividade,
        originalMargin,
    } = rowData;

    const skin = useMemo(() => {
        let skin: Skin = 'gray';

        if (!novaMargem || !maxMargin || !minMargin) return skin;

        if (novaMargem > maxMargin * 100 || novaMargem < minMargin * 100) {
            skin = 'red';
        }

        return skin;
    }, [novaMargem, maxMargin, minMargin]);

    const marginVariation = useMemo(
        () => IPAMaths.variation.margem(novaMargem, originalMargin),
        [novaMargem, originalMargin],
    );

    return (
        <BaseCell rowData={rowData} {...props}>
            <BaseCell.MainContainer>
                <InputGroup>
                    <CurrencyInput
                        className="nova-margem"
                        skin={skin}
                        name={dataKey}
                        decimalSeparator=","
                        thousandSeparator="."
                        precision={1}
                        allowEmpty
                        allowNegative
                        onChangeEvent={(_m, _v, value) => {
                            handleUpdateCampo(dataKey, value, rowData, index);
                        }}
                        value={novaMargem || 0}
                        onBlur={(e) => {
                            handleBlur(rowData, index);
                            props.onBlur?.(e);
                        }}
                        selectOnFocus
                    />
                    <Whisper
                        trigger="click"
                        placement="bottomEnd"
                        speaker={
                            <MarginLimitInfo
                                content={rowData}
                                margin={novaMargem}
                                cpi={novaCompetitividade}
                            />
                        }
                    >
                        <InputAddon>%</InputAddon>
                    </Whisper>
                </InputGroup>
            </BaseCell.MainContainer>

            <BaseCell.SubContainer>
                {!!originalMargin && !!novaMargem ? (
                    <VariationBoxIndicator
                        className="box-indicator"
                        value={marginVariation}
                    />
                ) : (
                    <>--</>
                )}
            </BaseCell.SubContainer>
        </BaseCell>
    );
};

export default memo(MargemCell);
