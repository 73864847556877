import classNames from 'classnames';
import React, {
    ChangeEvent,
    FocusEvent,
    forwardRef,
    useCallback,
    useMemo,
    useRef,
} from 'react';
import styles from './CurrencyInput.module.scss';
import * as T from './CurrencyInput.types';
import { Mask } from './Mask';

const CurrencyInput = forwardRef<HTMLInputElement, T.CurrencyInputProps>(
    (
        {
            className,
            value = 0,
            selectOnFocus = false,
            precision = 0,
            thousandSeparator = '.',
            decimalSeparator = ',',
            allowEmpty = false,
            allowNegative = true,
            skin = 'gray',
            onChange,
            onChangeValue,
            onFocus,
            onChangeEvent,
            onBlur,
            ...props
        },
        ref,
    ) => {
        const focusRef = useRef(value);

        const currency = useMemo(() => {
            return {
                precision,
                thousandSeparator,
                decimalSeparator,
                allowEmpty,
                allowNegative,
            };
        }, [
            precision,
            thousandSeparator,
            decimalSeparator,
            allowEmpty,
            allowNegative,
        ]);

        const { mask } = useMemo(
            () => new Mask(value, currency),
            [value, currency],
        );

        const handleChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                const mask = new Mask(value, currency);
                onChange?.(event);
                onChangeEvent?.(event, mask.mask, mask.float);
                onChangeValue?.(mask.float);
            },
            [onChange, onChangeValue, onChangeEvent, currency],
        );

        const handleFocus = useCallback(
            (event: FocusEvent<HTMLInputElement>) => {
                onFocus?.(event);
                focusRef.current = value;
                if (selectOnFocus) event.target.select();
            },
            [onFocus, value, selectOnFocus],
        );

        const handleBlur = useCallback(
            (e: FocusEvent<HTMLInputElement>) => {
                const details = {
                    onFocusValue: focusRef.current,
                    onBlurValue: value,
                    hasChanges: focusRef.current !== value,
                };
                onBlur?.(e, details);
            },
            [focusRef, value, onBlur],
        );

        return (
            <input
                {...props}
                ref={ref}
                className={classNames(styles.input, className)}
                type="text"
                value={mask}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                data-skin={skin}
            />
        );
    },
);

CurrencyInput.displayName = 'CurrencyInput';

export { CurrencyInput };
