import { format } from 'date-fns';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../@types/RootState';
import {
    CHANGE_QUICK_FILTER_VALUE,
    QuickActionFilterLimitsKeys,
    RESET_QUICK_ACTION_FILTER,
    TOGGLE_QUICK_FILTER_ON,
    toggleQuickActionFilter,
    toggleQuickFilter,
} from '../../../../../../reducers/gerenciadorPrecos/quickActionsFilter';
import { getValidData } from '../../../utils';

export const useQuickActionFilter = () => {
    const state = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.quickActionsFilters;
    });

    const dispatch = useDispatch();

    function handleToggleOnChangedPrices() {
        dispatch(TOGGLE_QUICK_FILTER_ON('changedPrices'));
    }

    function handleToggleChangedPrice() {
        dispatch(
            toggleQuickFilter({
                name: 'changedPrices',
            }),
        );
    }

    function handleSelectFilterLimitsClick(
        event?: React.SyntheticEvent<Element, Event>,
    ) {
        event?.preventDefault();
        dispatch(
            toggleQuickFilter({
                name: 'brokenLimit',
            }),
        );
    }

    function handleToggleOnBrokenLimit() {
        dispatch(TOGGLE_QUICK_FILTER_ON('brokenLimit'));
    }

    function handleSelectFilterLimits(eventKey: any) {
        dispatch(
            CHANGE_QUICK_FILTER_VALUE({
                name: 'brokenLimit',
                value: eventKey,
            }),
        );
        handleToggleOnBrokenLimit();
    }

    function getMinDate(lastDays: any) {
        const today = new Date(Date.now());
        const day = format(
            new Date(today - (+lastDays - 1) * 24 * 60 * 60 * 1000),
            'yyyy-MM-dd',
        );

        return day;
    }

    function getQuickActionFilterModel() {
        const data = {
            brokenLimit: state.brokenLimit.isActive
                ? state.brokenLimit.value
                : null,
            changedPrices: state.changedPrices.isActive || null,
            competitorPrices: state.competitorPrices.isActive,
            minDateCompetitorPrice: state.minDateCompetitorPrice.isActive
                ? getMinDate(state.minDateCompetitorPrice.value)
                : null,
            ...(state.isProductMaster.isActive && {
                isProductMaster: state.isProductMaster.isActive,
            }),
            competitorPriceSources:
                state.minDateCompetitorPrice.competitorPriceSources,
        };
        return getValidData<typeof data>(data);
    }

    function handleCleanQuickActionFlters() {
        dispatch(RESET_QUICK_ACTION_FILTER());
    }

    function handleToggleQuickActionFilter(key: QuickActionFilterLimitsKeys) {
        dispatch(toggleQuickActionFilter({ key }));
    }

    return {
        state,
        handleToggleChangedPrice,
        handleSelectFilterLimits,
        handleSelectFilterLimitsClick,
        getQuickActionFilterModel,
        handleToggleOnChangedPrices,
        handleCleanQuickActionFlters,
        handleToggleOnBrokenLimit,
        handleToggleQuickActionFilter,
    };
};
