import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from 'rsuite';
import { LoadingSpiner } from '../../../../../../../components/LoadingSpinner';
import { FiltersArea } from '../../Components/FiltersArea/Filters';
import { ForecastDetails } from '../../Components/ForecastDetailsArea/ForecastDetails';
import { Header } from '../../Components/Header/Header';
import { ProductDetails } from '../../Components/ProductDetails/ProductDetails';
import { SimulatorArea } from '../../Components/SimulatorArea/Inputs';
import { TableArea } from '../../Components/TableArea/TableArea';

import styles from './Principal.module.scss';
const PrevisaoDemandaV2 = () => {
    return (
        <Container className="main-container ">
            <div className={styles.container}>
                <LoadingSpiner size="md" />
                <Header />
                <FiltersArea />
                <SimulatorArea />
                <ProductDetails />
                <ForecastDetails />
                <TableArea />
            </div>
        </Container>
    );
};

export default withRouter(PrevisaoDemandaV2);
