import React from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { SetupIPAHeader } from '../../SetupIPA/elements';
import styles from '../SetupIPADadosDeConcorrencia.module.scss';
import CompetitorDaysInput from './CompetitorDaysInput';
import SuppliersCompetitorDaysInput from './SuppliersCompetitorDaysInput';

export const PricePreferenceSection = ({ control }: { control: Control<FieldValues> }) => (
    <div className={styles['setup-ipa__price-preference-competitor']}>
        <SetupIPAHeader
            title="Preferências do preço concorrente"
            subtitle="Defina o período de tempo que deve ser considerado no uso do preço concorrente"
        />
        <div className={styles['setup-ipa__price-preference-competitor__content']}>
            <h4>Total de dias do preço concorrente para precificação</h4>
            <p>Somente preços concorrentes que estiverem dentro do total de dias definido serão considerados no processo de precificação.</p>
            <CompetitorDaysInput control={control} />
        </div>
        <div className={styles['setup-ipa__price-preference-competitor__content']}>
            <h4>Total de dias do preço concorrente para negociação com fornecedores</h4>
            <p>Somente preços concorrentes que estiverem dentro do total de dias definido serão considerados no módulo de Negociação com fornecedores.</p>
            <SuppliersCompetitorDaysInput control={control} />
        </div>
    </div>
);
