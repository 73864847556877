import classNames from "classnames";
import dayjs from "dayjs";
import React, { useCallback } from "react";
import { Tooltip, Whisper } from "rsuite";
import { RowData } from "../../../../../../reducers/gerenciadorPrecos/datapoints";
import formatToBRL from "../../../../../../utils/formatToBRL";
import styles from "./PMCCel.module.scss";

type PmcPriceTagProps = {
    pmcPrice: any;
    date: string;
    className?: string;
    rowData: RowData;
};

const PmcPriceTag = (({ pmcPrice, date, className, rowData }: PmcPriceTagProps) => {
    const isRed = pmcPrice !== null && rowData?.price !== null && pmcPrice < rowData?.price;

    const calculateVariation = useCallback(() => {
        if (rowData?.price !== null && rowData.price !== 0 && pmcPrice !== null) {
            return (((rowData.price / pmcPrice) - 1) * 100).toFixed(1) + "%";
        }
        return "-";
    }, [pmcPrice, rowData?.price]);

    const variation = calculateVariation();

    return (
        <div className={classNames(styles.priceTag, className)}>
            <span
                className={classNames(styles.price, {
                    [styles.redPrice]: isRed,
                    [styles.extraPadding]: pmcPrice === null
                })}
            >
                {pmcPrice ? formatToBRL(pmcPrice) : "--"}
            </span>
            <div className={styles.details}>
                {variation !== "-" ? (
                    <Whisper speaker={<Tooltip> Diferença em relação ao preço sugerido</Tooltip>}>
                        <span className={classNames(styles.discount, { [styles.redDiscount]: isRed })}>
                            {isRed ? `+${variation.replace(".", ",")}` : `${variation.replace(".", ",")}`}
                        </span>
                    </Whisper>

                ) : null}
                {date ? (
                    <span className={styles.date}>{dayjs(date).format("DD/MM/YYYY")}</span>
                ) : null}
            </div>
        </div>
    );
});

export default PmcPriceTag;
