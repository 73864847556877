import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Modal } from 'rsuite';

import { selectorDemandForecast } from '../../../../../../../reducers/previsaoDemanda';
import { setScenario } from '../../../../../../../reducers/previsaoDemanda/outputSavedSimulations';
import styles from './ModalSaveScenario.module.scss';

const { Header, Title, Body, Footer } = Modal;

type ModalSaveScenarioProps = {
    show?: boolean;
    title?: string;
    onHide?: () => void;
    onClickSave?: () => void;
};

export const ModalSaveScenario = ({
    show,
    title,
    onHide,
    onClickSave,
}: ModalSaveScenarioProps) => {
    const dispatch = useDispatch();

    const { outputSavedSimulations, outputArea } = useSelector(
        selectorDemandForecast,
    );

    const handleChangeName = (value: string) => {
        dispatch(
            setScenario({
                ...outputArea.scenario,
                scenarioName: value,
            }),
        );
    };

    return (
        <Modal className={styles.modal} show={show} onHide={onHide}>
            <Header onHide={onHide}>
                <Title>{title} cenário</Title>
            </Header>
            <Body>
                <label
                    htmlFor="scenario-name"
                    className={styles['input-label']}
                >
                    Nome do cenário
                </label>
                <Input
                    id="scenario-name"
                    name="scenario-name"
                    className={styles['input-name']}
                    value={outputSavedSimulations.scenario?.scenarioName || ''}
                    onChange={handleChangeName}
                />
            </Body>
            <Footer>
                <Button
                    className={styles['btn-cancel']}
                    appearance="ghost"
                    onClick={onHide}
                >
                    Fechar
                </Button>
                <Button
                    id="btn-save-scenario"
                    disabled={!outputSavedSimulations.scenario?.scenarioName}
                    className={styles['btn-save']}
                    appearance="primary"
                    onClick={onClickSave}
                >
                    Salvar
                </Button>
            </Footer>
        </Modal>
    );
};
