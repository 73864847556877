import React from "react";
import { Toggle } from "rsuite";
import { SetupIPAHeader } from "../../SetupIPA";
import styles from "../SetupFarma.module.scss";
import { useFarma } from "../hooks/useFarma";

export const SetupPMCConfiguration = () => {
    const {
        usePMCForIPAPricing,
        isUpdating,
        setValue,
    } = useFarma();

    return (
        <div className={styles['setup-farma__price-preference']}>
            <SetupIPAHeader
                title="PMC - Preço Máximo ao Consumidor"
                subtitle="Use o Preço Máximo ao Consumidor atualizado para garantir que os seus preços estejam de acordo com as regras"
            />
            <div className={styles['setup-farma__pmc-toggle-container']}>
                <Toggle
                    size="sm"
                    checked={usePMCForIPAPricing}
                    onChange={(checked) => setValue("usePMCForIPAPricing", checked)}
                    disabled={isUpdating}
                />
                <div className={styles['setup-farma__pmc-text']}>
                    <p className={styles['setup-farma__pmc-title']}>Usar o PMC para precificar no IPA</p>
                    <p className={styles['setup-farma__pmc-description']}>
                        O PMC é o preço-teto autorizado para o comércio varejista de medicamentos.
                        Garanta que os seus preços estejam dentro do teto.
                    </p>
                </div>
            </div>
        </div>
    );
};
