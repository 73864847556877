import { combineReducers } from 'redux';
import precoAtacadoReducer from './PrecoAtacado/root';
import calculadoraPrevisaoDemandaReducer from './calculadoraPrevisaoDemanda/calculadoraPrevisaoDemandaReducer';
import extracaoDadosReducer from './extracaoDados/extracaoDadosReducer';
import datapointExhibitionType from './gerenciadorPrecos/datapointExhibitionType';
import gerenciadorPrecosReducer from './gerenciadorPrecos/root';
import getFamilyModalReducer from './getFamilyModal/root';
import negociacaoFornecedorReducer from './negociacaoFornecedor/negociacaoFornecedor.reducer';
import demandForecastReducer from './previsaoDemanda/demandForecastReducer';
import adminDataReducer from './reducerAdmin';
import analiseCompetitividadeDataReducer from './reducerAnaliseCompetitividade';
import clienteDataReducer from './reducerCliente';
import accountFreemiumDataReducer from './reducerCreateAccountFreemium';
import extracaoDadosDataReducer from './reducerExtracaoDados';
import dataFiltrosReducer from './reducerFiltrosData';
import historicoExtracaoPrecoDataReducer from './reducerHistoricoExtracaoPreco';
import historicoPrecoDataReducer from './reducerHistoricoPreco';
import reducerIPA from './reducerIPA/reducerIPA';
import limitesPrecoCadastroDataReducer from './reducerLimitesPrecoCadastro';
import lojasConcorrentesDataReducer from './reducerLojasConcorrentes';
import menuDataReducer from './reducerMenu';
import moduloRelatorioDataReducer from './reducerModuloRelatorio';
import navbarDataReducer from './reducerNavbar';
import navbarAdminDataReducer from './reducerNavbarAdmin';
import onDemandReducer from './reducerOnDemand';
import painelGeralDataReducer from './reducerPainelGeral';
import precificacaoManualDataReducer from './reducerPrecificacaoManual';
import precoPsicologicoDataReducer from './reducerPrecoPsicologico';
import productConfigurationDataReducer from './reducerProductConfiguration';
import regraAtacadoCadastroDataReducer from './reducerRegraAtacadoCadastro';
import regraPrecoCadastroDataReducer from './reducerRegraPrecoCadastro';
import revisaoDataReducer from './reducerRevisao';
import revisaoListaDataReducer from './reducerRevisaoLista';
import shareAnalysisDataReducer from './reducerShareAnalysis';
import usuarioDataReducer from './reducerUsuario';

export * from './gerenciadorPrecos';

export default combineReducers({
    navbarDataReducer,
    navbarAdminDataReducer,
    menuDataReducer,
    adminDataReducer,
    usuarioDataReducer,
    clienteDataReducer,
    painelGeralDataReducer,
    precificacaoManualDataReducer,
    revisaoDataReducer,
    regraPrecoCadastroDataReducer,
    precoPsicologicoDataReducer,
    limitesPrecoCadastroDataReducer,
    historicoExtracaoPrecoDataReducer,
    regraAtacadoCadastroDataReducer,
    accountFreemiumDataReducer,
    historicoPrecoDataReducer,
    extracaoDadosDataReducer,
    productConfigurationDataReducer,
    revisaoListaDataReducer,
    shareAnalysisDataReducer,
    moduloRelatorioDataReducer,
    analiseCompetitividadeDataReducer,
    lojasConcorrentesDataReducer,
    dataFiltrosReducer,
    gerenciadorPrecosReducer,
    onDemandReducer,
    negociacaoFornecedorReducer,
    calculadoraPrevisaoDemandaReducer,
    extracaoDadosReducer,
    reducerIPA,
    precoAtacadoReducer,
    datapointExhibitionType,
    getFamilyModalReducer,
    demandForecastReducer,
});

export type IRootState = ReturnType<typeof combineReducers>;
