import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    LoadingSpinerArea,
    SetupMenu,
    SetupMenuItem,
} from '../../../../../components';
import History from '../../../../../utils/History';
import { SetupFarma } from '../SetupFarma';
import { SetupIPADadosDeConcorrencia } from '../SetupIPADadosDeConcorrencia';
import { SetupIPAPrecoAtacado } from '../SetupIPAPrecoAtacado';
import styles from './SetupIPA.module.scss';
import { SetupIPAForm } from './elements';

const PREFIX_URL = '/administrador/produtos/IPA';

const MENU_ITEMS = {
    'dados-de-concorrencia': {
        to: 'dados-de-concorrencia',
        label: 'Dados de concorrência',
        component: SetupIPADadosDeConcorrencia,
    },
    'dados-do-infopanel': {
        to: 'dados-do-infopanel',
        label: 'Dados do InfoPanel',
        component: SetupIPAForm,
    },
    'canais-de-venda': {
        to: 'canais-de-venda',
        label: 'Canais de Venda',
        component: SetupIPAPrecoAtacado,
    },
    "farma": {
        to: "farma",
        label: "Farma",
        component: SetupFarma,
    },
} as const;

const { to: DEFAULT_PATH } = MENU_ITEMS['dados-de-concorrencia'];

type SetupIPAProps = RouteComponentProps<Record<string, string>> &
    React.HtmlHTMLAttributes<HTMLDivElement>;

const SetupIPA = ({ className, match, ...props }: SetupIPAProps) => {
    const params = match.params?.[0];

    const activePath = (params || DEFAULT_PATH) as keyof typeof MENU_ITEMS;

    const [selectedMenu, setSelectedMenu] = useState(activePath);

    useEffect(() => {
        setSelectedMenu(activePath);
    }, [activePath]);

    const MemoMenuItem = useMemo(
        () => MENU_ITEMS[selectedMenu].component,
        [selectedMenu],
    );

    if (!params) {
        History.push(`${PREFIX_URL}/${DEFAULT_PATH}`);
        return null;
    }

    return (
        <div className={classNames(styles['setup-ipa'], className)} {...props}>
            <SetupMenu className={styles['setup-ipa__setup-menu']}>
                {Object.entries(MENU_ITEMS).map(([key, { to, label }]) => (
                    <SetupMenuItem key={key} to={`${PREFIX_URL}/${to}`}>
                        {label}
                    </SetupMenuItem>
                ))}
            </SetupMenu>

            <div>
                <MemoMenuItem />
                <LoadingSpinerArea area="setup-ipa" height="100%" size="sm" />
            </div>
        </div>
    );
};

export default withRouter(SetupIPA);
