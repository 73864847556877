import { combineReducers } from '@reduxjs/toolkit';
import { RootState } from '../../../@types/RootState';
import competitorsDays from './competitorsDays';

const rootReducer = combineReducers({ competitorsDays });

export type IPAFormBasicConfig = ReturnType<typeof rootReducer>;

export const selectorIpaFormBasicConfig = (state: RootState) => {
    return state.reducerIPA.basicConfig;
};

export default rootReducer;
