export const useSearchParams = () => {
    const { location } = window;

    const pathname = `${location.origin}${location.pathname}`;
    const searchParams = location.search;

    return {
        setParam(key: string, param: string) {
            const params = new URLSearchParams(searchParams);
            params.set(key, param);
            window.history.replaceState({}, '', `${pathname}?${params.toString()}`);
        },
        getParam(key: string) {
            const params = new URLSearchParams(searchParams);
            return params.get(key);
        },
        deleteParam(key: string) {
            const params = new URLSearchParams(searchParams);
            params.delete(key);
            // window.location.replace(`${pathname}?${params.toString()}`);
            window.history.replaceState({}, '', `${pathname}?${params.toString()}`);
        },

        clearParams() {
            // window.location.replace(`${pathname}`);
            window.history.replaceState({}, '', `${pathname}`);
        },
    };
};
