import React, { useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { generateReport } from '../../../../../../actions/actionsModuloRelatorio';
import { LoadingSpinerLocal } from '../../../../../../components/LoadingSpinner';
import ModalConfirm from '../../../../../../components/ModalConfirm';
import { trackEvent } from '../../../../../../utils/MatomoConfig';
import { setLocalDateISOFormat } from '../../utils';
import SaveReportNameConfigContentForm from '../SaveReportNameConfigContentForm';
import SaveReportSelectIntervalContentForm from '../SaveReportSelectIntervalContentForm';

import styles from './styles.module.scss';

export const ModalSaveReport = ({
    show,
    onCancel,
    loadingCounter,
    dataCounter,
}) => {
    const [active, setActive] = useState(null);
    const [days, setDays] = useState(0);
    const [intervalDays, setIntervalDays] = useState(0);
    const [confirmDisabledButton, setConfirmDisabledButton] = useState(true);
    const [goToFileNameContent, setGoToFileNameContent] = useState(false);
    const [intervalDaysObj, setIntervalDaysObj] = useState({
        dataInicio: '',
        dataFim: '',
    });

    const formRef = useRef();

    const dispatch = useDispatch();

    const { isSpecificDates, moduloRelatorioData, reportLineQuantity } =
        useSelector(
            (state) => ({
                isSpecificDates:
                    state.moduloRelatorioDataReducer?.isSpecificDates,
                moduloRelatorioData:
                    state.moduloRelatorioDataReducer?.moduloRelatorioData,
                reportLineQuantity:
                    state.moduloRelatorioDataReducer?.reportLineQuantity,
            }),
            shallowEqual,
        );

    const handleDaysRange = (type, number) => {
        setDays(number);

        if (type === 'latest') {
            setActive(number);
            setConfirmDisabledButton(false);
            if (intervalDays > 0) {
                setIntervalDays(0);
            }
        }

        if (type === 'interval') {
            setIntervalDays(number);
            if (number > 0) {
                setActive(1);
                setConfirmDisabledButton(false);
            } else {
                setActive(null);
                setConfirmDisabledButton(true);
            }
        }
    };

    const handleGoToReportNameConfig = () => {
        const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
        const day = new Date(yesterday - (+days - 1) * 24 * 60 * 60 * 1000);
        setIntervalDaysObj((prevState) => ({
            ...prevState,
            dataInicio: setLocalDateISOFormat(day),
            dataFim: setLocalDateISOFormat(yesterday),
        }));
        setGoToFileNameContent(true);
    };

    const getFormObjectAndSaveReport = (data) => {
        let newData = {};
        if (data?.fileName) {
            newData = {
                ...moduloRelatorioData,
                dataInicio: isSpecificDates
                    ? intervalDaysObj.dataInicio
                    : moduloRelatorioData.dataInicio,
                dataFim: isSpecificDates
                    ? intervalDaysObj.dataFim
                    : moduloRelatorioData.dataFim,
                fileName: data.fileName,
                relatorioSalvo: true,
                recorrencia: data.recorrencia,
                reportEngineRecorrenciaDTO: data.recorrencia
                    ? {
                          recorrencia: data.repetition,
                          dataInicioEnvio: setLocalDateISOFormat(
                              data.initialDate,
                          ),
                          diasRecorrencia:
                              data.repetition === 'DIARIA'
                                  ? [data.dailyFrequency]
                                  : data.weeklyFrequency,
                      }
                    : null,
            };

            dispatch(generateReport(newData));
            onCancel();
        }
    };

    const handleFireFormSubmit = () => {
        formRef.current.dispatchEvent(
            new Event('submit', { cancelable: true, bubbles: true }),
        );
        trackEvent('Módulo de relatórios', 'Salvar relatório');
    };

    const handleOnCancel = () => {
        setIntervalDaysObj((prevState) => ({
            ...prevState,
            dataInicio: '',
            dataFim: '',
        }));
        setActive(null);
        setConfirmDisabledButton(true);
        setIntervalDays(0);
        setGoToFileNameContent(false);
        onCancel();
    };

    const setModalMessage = (line) => {
        const formatNumber = new Intl.NumberFormat('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(Number(line));

        if (formatNumber === 'NaN') {
            return 0;
        }

        return formatNumber;
    };

    const counter = (
        <span className={styles.counter}>
            <LoadingSpinerLocal
                loading={loadingCounter}
                size="sm"
                position="absolute"
            />
            Agora, este relatório irá gerar{' '}
            <span className={styles['counter-number']}>
                {setModalMessage(dataCounter?.data)}
            </span>{' '}
            linhas.
        </span>
    );

    return (
        <ModalConfirm
            show={show}
            className={styles['modal-save-report']}
            modalWidth="597px"
            titleFontSize="20px"
            footerAlign="end"
            closeButton
            title="Salvar relatório"
            message={counter}
            confirmButton={
                isSpecificDates && !goToFileNameContent
                    ? 'Prosseguir'
                    : 'Salvar'
            }
            cancelButton="Cancelar"
            confirmDisabled={
                isSpecificDates && !goToFileNameContent
                    ? confirmDisabledButton
                    : false
            }
            onCancel={handleOnCancel}
            onHideHeaderButton={handleOnCancel}
            onConfirm={
                isSpecificDates && !goToFileNameContent
                    ? handleGoToReportNameConfig
                    : handleFireFormSubmit
            }
            confirmButtonId={
                isSpecificDates && !goToFileNameContent
                    ? null
                    : 'btn-modal-save-report'
            }
        >
            {isSpecificDates && !goToFileNameContent ? (
                <SaveReportSelectIntervalContentForm
                    onClick={(v) => handleDaysRange('latest', v)}
                    active={active}
                    InputNumberValue={intervalDays}
                    InputNumberOnChange={(v) => handleDaysRange('interval', v)}
                />
            ) : (
                <SaveReportNameConfigContentForm
                    formRef={formRef}
                    submit={getFormObjectAndSaveReport}
                />
            )}
        </ModalConfirm>
    );
};
