type styleFormat = 'currency' | 'decimal' | 'percent';

type options = {
    maximumFractionDigits?: number;
    minimumFractionDigits?: number;
    showSuffix?: boolean;
};

export const formatNumbersToPtBr = (
    value: number,
    style: styleFormat = 'currency',
    {
        maximumFractionDigits = 2,
        minimumFractionDigits = 0,
        showSuffix = true,
    }: options = {},
) => {
    if (!value) {
        return 0;
    }

    let suffix = '';
    let number = value;
    let forcedFractionDigits = 0;

    if (value >= 1_000_000 && showSuffix) {
        forcedFractionDigits = 2;
        number = value / 1_000_000;
        suffix = 'M';
    } else if (value >= 1_000 && showSuffix) {
        forcedFractionDigits = 2;
        number = value / 1_000;
        suffix = 'K';
    }

    const maxFractionDigits = forcedFractionDigits
        ? forcedFractionDigits
        : maximumFractionDigits;

    const minFractionDigits = forcedFractionDigits
        ? forcedFractionDigits
        : minimumFractionDigits;

    return (
        Intl.NumberFormat('pt-BR', {
            style,
            currency: 'BRL',
            maximumFractionDigits: maxFractionDigits,
            minimumFractionDigits: minFractionDigits,
        }).format(number) + ` ${suffix}`
    );
};
