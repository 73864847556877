import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from '../../../../../../hooks/useSearchParams';
import { selectorDemandForecast } from '../../../../../../reducers/previsaoDemanda';
import {
    FiltersNameProps,
    resetFilters,
    setFilter,
    setProducts,
    setStores,
} from '../../../../../../reducers/previsaoDemanda/filterArea';
import { clearInputData } from '../../../../../../reducers/previsaoDemanda/inputArea';
import { clearOutputData } from '../../../../../../reducers/previsaoDemanda/outputArea';
import { searchFiltroProdutos } from '../../../../../../services/ProdutoService';
import { ProductList } from '../@types/product';
import { ProductDto, ProductResponse } from '../@types/product-response';
import { StoreDto } from '../@types/store-response';
import { getStores } from '../services';

export const useFiltersSavedScenarios = () => {
    const dispatch = useDispatch();
    const { getParam, deleteParam } = useSearchParams();
    const { filtersArea } = useSelector(selectorDemandForecast);

    const listProducts = async (query?: string) => {
        const response: ProductResponse = await searchFiltroProdutos({
            query: query || getParam('productId'),
        });
        if (response) {
            const products = response.data.map((item: ProductDto) => ({
                label: `${item.product_id} - ${item.description}`,
                value: item.product_id,
                gtin: item.gtin as string,
                description: item.description,
                productId: item.product_id,
            }));
            dispatch(setProducts({ products }));
            return products;
        }
        return [];
    };

    const listStores = async (query?: string[]) => {
        const response: StoreDto[] = await getStores({ query: query });
        if (response) {
            const storesResponse = response?.map((item) => ({
                value: item.store_id,
                label: item.store,
                store_id: item.store_id,
                description: item.store,
            }));

            const { list, selectedFilters } = filtersArea;

            if (query?.length === 0) {
                dispatch(setStores({ stores: storesResponse }));

                return storesResponse;
            }

            const storesSelected = list.stores.filter((store) =>
                selectedFilters.storesId.includes(store.store_id),
            );

            dispatch(
                setStores({
                    stores: [...storesSelected, ...storesResponse],
                }),
            );
            return [...storesSelected, ...storesResponse];
        }
        return [];
    };

    const handleSetFilter = ({
        name,
        filterValue,
        item,
    }: {
        name: FiltersNameProps;
        filterValue: string | any | Date[];
        item?: ProductList;
    }) => {
        dispatch(setFilter({ name, filterValue }));
        dispatch(clearInputData());
        dispatch(clearOutputData());
    };

    const clearFilters = () => {
        dispatch(resetFilters());
        dispatch(clearInputData());
        dispatch(clearOutputData());
    };

    const sicronizeParams = () => {
        const productId = getParam('productId') || '';
        const storesId = getParam('storesId')?.split(',') || [];
        const filterDate = getParam('filterDate')?.split('-');
        const dateRangeValue = filterDate?.length
            ? [new Date(filterDate[0]), new Date(filterDate[1])]
            : [];

        dispatch(setFilter({ name: 'productId', filterValue: productId }));
        dispatch(setFilter({ name: 'storesId', filterValue: storesId }));
        dispatch(setFilter({ name: 'dateRange', filterValue: dateRangeValue }));
    };

    const handleClearDatePicker = () => {
        deleteParam('filterDate');
        handleSetFilter({
            name: 'dateRange',
            filterValue: [],
        });
    };

    const { isLoading: isListStoresLoading } = useQuery({
        queryKey: [
            'demand-forecast-stores-saved-scenarios',
            filtersArea.search.stores,
        ],
        retry: true,
        keepPreviousData: true,
        queryFn: () =>
            listStores(
                filtersArea.search.stores ? [filtersArea.search.stores] : [],
            ),
    });

    useEffect(() => {
        sicronizeParams();
    }, []);

    return {
        isListStoresLoading,
        handleSetFilter,
        listProducts,
        listStores,
        clearFilters,
        handleClearDatePicker,
    };
};
