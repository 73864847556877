import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'rsuite';
import { selectorDemandForecast } from '../../../../../../../reducers/previsaoDemanda';

import { FaSave } from 'react-icons/fa';
import { MdDownload } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useHandleScenarios } from '../../hooks/useHandleScenarios';
import { ModalSaveScenario } from '../ModalSaveScenario';
import { ModalUpdateScenario } from '../ModalUpdateScenario';
import styles from './ProductDetails.module.scss';
export const ProductDetails = () => {
    const { filtersArea, outputArea, outputSavedSimulations } = useSelector(
        selectorDemandForecast,
    );

    const params = useParams() as Record<'id', string>;

    const buttonDisable = filtersArea.buttons.getOutput.isDisabled;

    const { showModalSave, showModalUpdate } = outputSavedSimulations.modal;

    const {
        handleGetDemandForecastSimulate,
        closeAllModals,
        handleShowModal,
        handleSaveScenario,
        handleDownloadScenario,
        handleUpdateScenario,
        handleOpenModalSave,
    } = useHandleScenarios();

    const isDisabled = !outputArea.calculations.hasSimulation;

    return (
        <main className={styles.simulation}>
            <div className={styles['simulation-selected-items']}>
                {filtersArea?.isCompleted && (
                    <>
                        <h3>{filtersArea?.activeFilter?.productLabel}</h3>
                        <p>
                            <span>
                                {filtersArea?.activeFilter?.storeOrClusterLabel}
                            </span>
                            <span>{filtersArea?.activeFilter?.date}</span>
                        </p>
                    </>
                )}
            </div>

            <div className={styles['simulation-buttons']}>
                <div className={styles['action-buttons']}>
                    <Button
                        disabled={isDisabled}
                        className={styles['icon-button']}
                        onClick={handleDownloadScenario}
                    >
                        <MdDownload size={20} />
                    </Button>
                    <Button
                        disabled={isDisabled}
                        className={styles['icon-button']}
                        onClick={() => handleShowModal(params?.id)}
                    >
                        <FaSave size={20} />
                        {/* <span className={styles['save-icon']}>
                            <FaSave />
                            <FaChevronDown />
                        </span> */}
                    </Button>
                </div>

                <Button
                    // size="lg"
                    className={styles['main-button']}
                    appearance="primary"
                    onClick={handleGetDemandForecastSimulate}
                    disabled={!filtersArea.isCompleted || buttonDisable}
                >
                    VISUALIZAR RESULTADOS
                </Button>
            </div>

            {showModalSave && (
                <ModalSaveScenario
                    show={showModalSave}
                    title={'Salvar'}
                    onHide={closeAllModals}
                    onClickSave={handleSaveScenario}
                />
            )}
            {showModalUpdate && (
                <ModalUpdateScenario
                    show={showModalUpdate}
                    onClickSave={handleUpdateScenario}
                    onClickSaveNew={handleOpenModalSave}
                    onHide={closeAllModals}
                />
            )}
        </main>
    );
};
