import React, { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { RiPencilFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox } from 'rsuite';
import { InfoTable, Loading } from '../../../../../../../../components';
import { selectorDemandForecast } from '../../../../../../../../reducers/previsaoDemanda';
import { ScenariosProps } from '../../../../../../../../reducers/previsaoDemanda/demandForecastSimulations';
import { formatNumbersToPtBr } from '../../../../../../../../utils/FormatNumbers';
import { CheckBoxCell } from '../../../../../../RevisaoPrecos/Components';
import { useTableActions } from '../../../hooks/useTableActions';
import {
    generateElasticity,
    generateElasticityValue,
} from '../../../utils/generateElasticity';
import { normalizeMargin } from '../../../utils/normalizeValue';
import { CellWrapper } from '../../TableArea/Components/CustomCell/CellWrapper';
import { Highlight } from '../../TableArea/Components/Highlight/Highlight';
import styles from './TableSavedSimulations.module.scss';

export const TableSavedSimulations = () => {
    const { Column, HeaderCell, Cell } = InfoTable;

    const history = useHistory();
    const { tableHandlers, tableState } = useTableActions();
    const { demandForecastSavedScenarios } = useSelector(
        selectorDemandForecast,
    );

    const queryClient = useQueryClient();

    const { pagination, calculations } = demandForecastSavedScenarios;
    const { allChecked, hasSomeChecked } = calculations;

    const handleEdit = (id: any) => {
        history.push(`/ipa/simulacoes-analises/previsao-demanda-v2/${id}`);
    };

    const lengthMenu = [
        {
            value: 10,
            label: 10,
        },
        {
            value: 20,
            label: 20,
        },
    ];

    const defaultOptionsFormat = {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    };

    const paginationData = {
        total: pagination.totalElements,
        activePage: pagination.activePage + 1,
        displayLength: pagination.size,
        lengthMenu,
        onChangePage: tableHandlers.handleChangePage,
        onChangeLength: tableHandlers.handleChangeSize,
    };

    const isIndeterminate = !allChecked && hasSomeChecked;

    const formatMargin = (value: number) => {
        return formatNumbersToPtBr(normalizeMargin(value), 'decimal', {
            maximumFractionDigits: 1,
            minimumFractionDigits: 1,
            showSuffix: false,
        });
    };

    useEffect(() => {
        queryClient.resetQueries(['saved-scenarios']);
    }, []);

    return (
        <main className={styles['section-table']}>
            {tableState.isLoadingScenarios ? (
                <div className={styles.loading}>
                    <Loading />
                </div>
            ) : (
                <InfoTable
                    data={
                        demandForecastSavedScenarios?.savedScenarios?.list || []
                    }
                    className={styles.table}
                    loading={tableState.isLoadingScenarios}
                    minHeight={50}
                    headerHeight={52}
                    rowHeight={62}
                    sortColumn={tableState.sortColumn}
                    sortType={tableState.orderBy}
                    onSortColumn={tableHandlers.handleSortColumns}
                    pagination={paginationData}
                >
                    {/* @ts-ignore */}
                    <Column width={32}>
                        <HeaderCell>
                            <Checkbox
                                checked={
                                    demandForecastSavedScenarios.calculations
                                        .allChecked
                                }
                                indeterminate={isIndeterminate}
                                onChange={(_, checked) => {
                                    tableHandlers.handleCheckAll(checked);
                                }}
                            />
                        </HeaderCell>
                        <Cell>
                            {(rowData: ScenariosProps, rowIndex: number) => {
                                const value = rowData.query.productId;
                                return (
                                    <CheckBoxCell
                                        value={value}
                                        isChecked={!!rowData.checked}
                                        handleCheck={(_, checked) => {
                                            tableHandlers.handleCheck(
                                                rowIndex,
                                                checked,
                                                rowData,
                                            );
                                        }}
                                    />
                                );
                            }}
                        </Cell>
                    </Column>

                    {/* @ts-ignore */}
                    <Column sortable flexGrow={2}>
                        <HeaderCell>Cenário</HeaderCell>
                        <Cell
                            className={styles['padding-inline-xxs']}
                            dataKey="scenario.scenario.scenario_name"
                        >
                            {(rowData: ScenariosProps) => {
                                return (
                                    <CellWrapper
                                        className={styles['cell-wrapper']}
                                    >
                                        <div className={styles['main-content']}>
                                            {rowData.scenario.scenarioName}
                                        </div>
                                    </CellWrapper>
                                );
                            }}
                        </Cell>
                    </Column>

                    {/* @ts-ignore */}
                    <Column sortable flexGrow={1}>
                        <HeaderCell>Produto</HeaderCell>
                        <Cell
                            className={styles['padding-inline-xxs']}
                            dataKey="scenario.query.product_id"
                        >
                            {(rowData: ScenariosProps) => (
                                <CellWrapper className={styles['cell-wrapper']}>
                                    <div className={styles['main-content']}>
                                        {rowData.query.productId}
                                    </div>
                                    <div
                                        className={styles['secondary-content']}
                                    >
                                        <span>{rowData.query.productName}</span>
                                    </div>
                                </CellWrapper>
                            )}
                        </Cell>
                    </Column>

                    {/* @ts-ignore */}
                    <Column sortable flexGrow={1}>
                        <HeaderCell>Loja</HeaderCell>
                        <Cell
                            className={styles['padding-inline-xxs']}
                            dataKey="scenario.query.store_ids"
                        >
                            {(rowData: ScenariosProps) => (
                                <CellWrapper className={styles['cell-wrapper']}>
                                    <div className={styles['main-content']}>
                                        {rowData.query.storeIds.join(', ')}
                                    </div>
                                </CellWrapper>
                            )}
                        </Cell>
                    </Column>

                    {/* @ts-ignore */}
                    <Column sortable width={96} resizable>
                        <HeaderCell>Elasticidade</HeaderCell>
                        <Cell
                            className={styles['padding-inline-xxs']}
                            dataKey="scenario.output.elasticity"
                        >
                            {(rowData: ScenariosProps) => (
                                <CellWrapper className={styles['cell-wrapper']}>
                                    <div className={styles['main-content']}>
                                        {generateElasticityValue(
                                            rowData.output.elasticity,
                                        )}
                                    </div>
                                    <div
                                        className={styles['secondary-content']}
                                    >
                                        <span>
                                            {
                                                generateElasticity(
                                                    rowData.output.elasticity,
                                                )?.classification
                                            }
                                        </span>
                                    </div>
                                </CellWrapper>
                            )}
                        </Cell>
                    </Column>

                    {/* @ts-ignore */}
                    <Column sortable width={80} resizable>
                        <HeaderCell>Preço</HeaderCell>
                        <Cell
                            className={styles['padding-inline-xxs']}
                            dataKey="scenario.output.new_price_scenario.price"
                        >
                            {(rowData: ScenariosProps) => (
                                <CellWrapper className={styles['cell-wrapper']}>
                                    <div className={styles['main-content']}>
                                        {formatNumbersToPtBr(
                                            rowData.output.newPriceScenario
                                                .price,
                                            'currency',
                                            defaultOptionsFormat,
                                        )}
                                    </div>
                                    <div
                                        className={styles['secondary-content']}
                                    >
                                        <div>
                                            {formatNumbersToPtBr(
                                                rowData.output.basePriceScenario
                                                    .price,
                                                'currency',
                                                defaultOptionsFormat,
                                            )}
                                        </div>
                                    </div>
                                </CellWrapper>
                            )}
                        </Cell>
                    </Column>

                    {/* @ts-ignore */}
                    <Column sortable width={80} resizable>
                        <HeaderCell>Margem</HeaderCell>
                        <Cell
                            className={styles['padding-inline-xxs']}
                            dataKey="scenario.output.new_price_scenario.margin"
                        >
                            {(rowData: ScenariosProps) => (
                                <CellWrapper className={styles['cell-wrapper']}>
                                    <div
                                        className={styles['main-content']}
                                    >{`${formatMargin(rowData.output.newPriceScenario?.margin)} %`}</div>
                                    <div
                                        className={styles['secondary-content']}
                                    >
                                        <div>{`${formatMargin(rowData.output.basePriceScenario?.margin)} %`}</div>
                                    </div>
                                </CellWrapper>
                            )}
                        </Cell>
                    </Column>

                    {/* @ts-ignore */}
                    <Column sortable width={112} resizable>
                        <HeaderCell>Vendas (un)</HeaderCell>
                        <Cell
                            className={styles['padding-inline-xxs']}
                            dataKey="scenario.output.new_price_scenario.demand_forecast"
                        >
                            {(rowData: ScenariosProps) => (
                                <CellWrapper className={styles['cell-wrapper']}>
                                    <div className={styles['main-content']}>
                                        {formatNumbersToPtBr(
                                            rowData.output.newPriceScenario
                                                .demandForecast,
                                            'decimal',
                                            {
                                                maximumFractionDigits: 0,
                                                minimumFractionDigits: 0,
                                            },
                                        )}
                                    </div>
                                    <div
                                        className={styles['secondary-content']}
                                    >
                                        <span>
                                            {formatNumbersToPtBr(
                                                rowData.output.basePriceScenario
                                                    .demandForecast,
                                                'decimal',
                                                {
                                                    maximumFractionDigits: 0,
                                                    minimumFractionDigits: 0,
                                                },
                                            )}
                                        </span>
                                        <Highlight
                                            value={
                                                rowData.output
                                                    .scenariosVariation
                                                    .demandForecast
                                            }
                                        />
                                    </div>
                                </CellWrapper>
                            )}
                        </Cell>
                    </Column>

                    {/* @ts-ignore */}
                    <Column sortable width={144} resizable>
                        <HeaderCell>Vendas (R$)</HeaderCell>
                        <Cell
                            className={styles['padding-inline-xxs']}
                            dataKey="scenario.output.new_price_scenario.revenue_forecast"
                        >
                            {(rowData: ScenariosProps) => (
                                <CellWrapper className={styles['cell-wrapper']}>
                                    <div className={styles['main-content']}>
                                        {formatNumbersToPtBr(
                                            rowData.output.newPriceScenario
                                                .revenueForecast,
                                            'currency',
                                            defaultOptionsFormat,
                                        )}
                                    </div>
                                    <div
                                        className={styles['secondary-content']}
                                    >
                                        <span>
                                            {formatNumbersToPtBr(
                                                rowData.output.basePriceScenario
                                                    .revenueForecast,
                                                'currency',
                                                defaultOptionsFormat,
                                            )}
                                        </span>
                                        <Highlight
                                            value={
                                                rowData.output
                                                    .scenariosVariation
                                                    .revenueForecast
                                            }
                                        />
                                    </div>
                                </CellWrapper>
                            )}
                        </Cell>
                    </Column>

                    {/* @ts-ignore */}
                    <Column sortable width={144} resizable>
                        <HeaderCell>Lucro (R$)</HeaderCell>
                        <Cell
                            className={styles['padding-inline-xxs']}
                            dataKey="scenario.output.new_price_scenario.profit_forecast"
                        >
                            {(rowData: ScenariosProps) => (
                                <CellWrapper className={styles['cell-wrapper']}>
                                    <div className={styles['main-content']}>
                                        {formatNumbersToPtBr(
                                            rowData.output.newPriceScenario
                                                .profitForecast,
                                            'currency',
                                            defaultOptionsFormat,
                                        )}
                                    </div>
                                    <div
                                        className={styles['secondary-content']}
                                    >
                                        <span>
                                            {formatNumbersToPtBr(
                                                rowData.output.basePriceScenario
                                                    .profitForecast,
                                                'currency',
                                                defaultOptionsFormat,
                                            )}
                                        </span>

                                        <Highlight
                                            value={
                                                rowData.output
                                                    .scenariosVariation
                                                    .profitForecast
                                            }
                                        />
                                    </div>
                                </CellWrapper>
                            )}
                        </Cell>
                    </Column>

                    {/* @ts-ignore */}
                    <Column sortable flexGrow={1}>
                        <HeaderCell>Responsável</HeaderCell>
                        <Cell
                            className={styles['padding-inline-xxs']}
                            dataKey="scenario.scenario.client"
                        >
                            {(rowData: ScenariosProps) => (
                                <CellWrapper className={styles['cell-wrapper']}>
                                    <div className={styles['main-content']}>
                                        {rowData.scenario.username}
                                    </div>
                                    <div
                                        className={styles['secondary-content']}
                                    >
                                        <span>
                                            {new Date(
                                                `${rowData.scenario?.date}T00:00:00.175` ||
                                                    0,
                                            ).toLocaleDateString('pt-BR')}
                                        </span>
                                    </div>
                                </CellWrapper>
                            )}
                        </Cell>
                    </Column>

                    {/* @ts-ignore */}
                    <Column width={50}>
                        <HeaderCell />
                        <Cell>
                            {(rowData: ScenariosProps) => (
                                <div style={{ marginLeft: '8px' }}>
                                    <Button
                                        className={styles['icon-button']}
                                        onClick={() =>
                                            handleEdit(rowData.scenario.id)
                                        }
                                    >
                                        <RiPencilFill />
                                    </Button>
                                </div>
                            )}
                        </Cell>
                    </Column>
                </InfoTable>
            )}
        </main>
    );
};
