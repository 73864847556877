import { trackPromise } from "react-promise-tracker";
import api from "../../../../../../utils/API";
import { ConfigFarmaResponse } from "../types/ConfigFarmaResponse";

export interface UpdateConfigFarmaPayload {
    usePMCForIPAPricing: boolean;
}

export async function getConfigFarma(): Promise<ConfigFarmaResponse> {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/setup/config-farma`,
            method: 'GET',
            expectToken: true,
        }).then((response) => response.data),
        '',
    );
}
export async function updateConfigFarma(payload: UpdateConfigFarmaPayload): Promise<void> {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/setup/config-farma`,
            method: 'PUT',
            data: payload,
            expectToken: true,
        }).then((response) => response.data),
        '',
    );
}
