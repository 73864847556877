import React from 'react';
import { Container } from 'rsuite';
import { FiltersAreaSavedSimulations } from '../../Components/SavedSimulations/FiltersArea';
import { HeaderSavedSimulations } from '../../Components/SavedSimulations/Header';
import { ResultAndActionArea } from '../../Components/SavedSimulations/ResultAndActionArea';
import { TableSavedSimulations } from '../../Components/SavedSimulations/Table';

import styles from './SimulacaoSalvas.module.scss';
export const SimulacoesSalvasV2 = () => {
    return (
        <Container className="main-container">
            <div className={styles.container}>
                <HeaderSavedSimulations
                    urlToBack="/ipa/simulacoes-analises/previsao-demanda-v2"
                    labelToBack="Voltar para Previsão de demanda"
                    title="Simulações salvas de Previsão de demanda"
                />
                <FiltersAreaSavedSimulations />
                <ResultAndActionArea />
                <TableSavedSimulations />
            </div>
        </Container>
    );
};
