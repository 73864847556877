import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../@types/RootState';
import {
    CHANGE_QUICK_FILTER_VALUE,
    setMinDateCompetitorPriceSource,
    TOGGLE_QUICK_FILTER_ON,
    togglePriceCPI,
    toggleQuickFilterOff,
} from '../../../../../reducers/gerenciadorPrecos/quickActionsFilter';
import { ICompetitorPriceSources } from '../../../Entities';
import {
    BaseQuickActionFilterProps,
    QuickActionFilter,
} from '../QuickActionFilter';

const { SelectInterval } = QuickActionFilter;

const MENU_STYLE = { width: '300px' };

const DEFAULT_MAX_CPI_DAYS = 7;

export const CpiDaysFilter = () => {
    const dispatch = useDispatch();

    const minDateCompetitorPrice = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.quickActionsFilters
            .minDateCompetitorPrice;
    });

    const isCompetitorsPriceActive = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.quickActionsFilters
            .competitorPrices.isActive;
    });

    const [cpiDays, setCpiDays] = useState(DEFAULT_MAX_CPI_DAYS);

    const maxCpiDays = useSelector((state: RootState) => {
        return state.reducerIPA.basicConfig.competitorsDays?.competitor_days;
    });

    const handleToggleFilter = () => {
        dispatch(togglePriceCPI());
        dispatch(toggleQuickFilterOff({ name: 'minDateCompetitorPrice' }));
    };

    const handleCloseFilter = () => {
        dispatch(
            CHANGE_QUICK_FILTER_VALUE({
                name: 'minDateCompetitorPrice',
                value: cpiDays,
            }),
        );
        dispatch(TOGGLE_QUICK_FILTER_ON('minDateCompetitorPrice'));
    };

    const handleChangeCpiDays = (value: string | number) => {
        setCpiDays(Number(value));
    };

    const onChangeCpiSources = (sources: ICompetitorPriceSources[]) => {
        dispatch(setMinDateCompetitorPriceSource(sources));
    };

    const skin = useMemo(() => {
        let skin: BaseQuickActionFilterProps['skin'] = 'neutral';
        if (isCompetitorsPriceActive || minDateCompetitorPrice.isActive) {
            skin = 'primary';
        }
        return skin;
    }, [isCompetitorsPriceActive, minDateCompetitorPrice]);

    // if client has an global configuration for maxCpiDays, set it as default value
    useEffect(() => {
        if (maxCpiDays) setCpiDays(maxCpiDays);
    }, [maxCpiDays]);

    return (
        <SelectInterval
            inputValue={cpiDays.toString()}
            placeholder="Filtrar preços concorrentes"
            icon="MdStore"
            prefixLabel="Filtrar preço concorrente dos últimos"
            unitLabel="dias"
            maxValue={maxCpiDays}
            menuStyle={MENU_STYLE}
            skin={skin}
            onClick={handleToggleFilter}
            onChange={handleChangeCpiDays}
            onClose={handleCloseFilter}
            competitorPriceSources={{
                value: minDateCompetitorPrice.competitorPriceSources,
                onChange: onChangeCpiSources,
            }}
        />
    );
};
