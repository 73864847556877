import React from 'react';
import CurrencyInput from 'react-currency-input';
import { useDispatch, useSelector } from 'react-redux';
import { FlexboxGrid, Input, InputGroup } from 'rsuite';
import { selectorDemandForecast } from '../../../../../../../reducers/previsaoDemanda';
import { handleEnableButtonMain } from '../../../../../../../reducers/previsaoDemanda/filterArea';
import { setNewValue } from '../../../../../../../reducers/previsaoDemanda/inputArea';
import { clearOutputData } from '../../../../../../../reducers/previsaoDemanda/outputArea';
import { useFilters } from '../../hooks/useFilters';

import {
    normalizeCompetitiveness,
    normalizeMargin,
} from '../../utils/normalizeValue';
import styles from './Inputs.module.scss';

export const SimulatorArea = () => {
    const { simulatorArea } = useSelector(selectorDemandForecast);
    const { getDemandForecastInputsValue } = useFilters();

    const dispatch = useDispatch();

    const calculateProductPrice = (newPrice: string) => {
        const value = newPrice.replace(',', '.');
        dispatch(setNewValue({ newprice: +value }));
    };

    return (
        <main className={styles['simulation-container']}>
            <FlexboxGrid align="bottom" className={styles['page-title']}>
                <h3>Simulador</h3>
                <p>
                    Veja o resultado da simulação para cada uma das lojas
                    selecionadas
                </p>
            </FlexboxGrid>

            <section className={styles['input-section']}>
                {/* preco */}
                <div className={styles['input-wrapper']}>
                    <div>
                        <InputGroup>
                            <InputGroup.Addon>R$</InputGroup.Addon>
                            <CurrencyInput
                                className={[
                                    'rs-input',
                                    styles['input-container'],
                                ].join(' ')}
                                decimalSeparator=","
                                thousandSeparator="."
                                precision="2"
                                allowEmpty
                                value={simulatorArea.inputs.newPrice}
                                onChangeEvent={(e, value) =>
                                    calculateProductPrice(value)
                                }
                                onBlur={(e) => {
                                    const value = e.target.value.replace(
                                        ',',
                                        '.',
                                    );
                                    getDemandForecastInputsValue(Number(value));
                                    dispatch(
                                        handleEnableButtonMain({
                                            buttonName: 'getOutput',
                                            isDisabled: false,
                                        }),
                                    );
                                    dispatch(clearOutputData());
                                }}
                                onFocus={(e) =>
                                    dispatch(
                                        handleEnableButtonMain({
                                            buttonName: 'getOutput',
                                            isDisabled: true,
                                        }),
                                    )
                                }
                            />
                        </InputGroup>
                    </div>
                    <p className={styles['simulation-input-label']}>Preço</p>
                </div>

                <div className={styles.divider} />

                {/* competividade */}
                <div className={styles['input-wrapper']}>
                    <div>
                        {simulatorArea.inputs.competitiveness ? (
                            <InputGroup>
                                <CurrencyInput
                                    className={[
                                        'rs-input',
                                        styles['input-container'],
                                    ].join(' ')}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    precision="1"
                                    allowEmpty
                                    value={normalizeCompetitiveness(
                                        simulatorArea.inputs.competitiveness,
                                    )}
                                    disabled
                                />
                                <InputGroup.Addon>%</InputGroup.Addon>
                            </InputGroup>
                        ) : (
                            <InputGroup>
                                <Input
                                    className={[
                                        styles['input-container'],
                                        styles['input-empty'],
                                    ].join(' ')}
                                    disabled
                                    value="--"
                                />
                                <InputGroup.Addon>%</InputGroup.Addon>
                            </InputGroup>
                        )}
                    </div>
                    <p className={styles['simulation-input-label']}>
                        Competitividade
                    </p>
                </div>

                {/* margem */}
                <div className={styles['input-wrapper']}>
                    <div>
                        {simulatorArea.inputs.margin ? (
                            <InputGroup>
                                <CurrencyInput
                                    className={[
                                        'rs-input',
                                        styles['input-container'],
                                    ].join(' ')}
                                    name="margin"
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    precision="1"
                                    allowEmpty
                                    value={normalizeMargin(
                                        simulatorArea.inputs.margin,
                                    )}
                                    disabled
                                />
                                <InputGroup.Addon>%</InputGroup.Addon>
                            </InputGroup>
                        ) : (
                            <InputGroup>
                                <Input
                                    className={[
                                        styles['input-container'],
                                        styles['input-empty'],
                                    ].join(' ')}
                                    disabled
                                    value="--"
                                />
                                <InputGroup.Addon>%</InputGroup.Addon>
                            </InputGroup>
                        )}
                    </div>
                    <p className={styles['simulation-input-label']}>Margem</p>
                </div>
            </section>
        </main>
    );
};
