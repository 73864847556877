import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GerenciadorPrecos } from '../../../@types/GerenciadorPrecos';
import { RootState } from '../../../@types/RootState';

export type FiltersLoadingState = Partial<
    Record<GerenciadorPrecos.FilterKeys, boolean>
>;

const initialFiltersLoadingState: FiltersLoadingState = {};

const slice = createSlice({
    name: 'ipa.filters.loading',
    initialState: initialFiltersLoadingState,
    reducers: {
        SET_FILTERS_LOADING: (
            state,
            {
                payload,
            }: PayloadAction<{
                key: GerenciadorPrecos.FilterKeys;
                value: boolean;
            }>,
        ) => {
            state[payload.key] = payload.value;
        },
        RESET_FILTERS_LOADING: () => {
            return initialFiltersLoadingState;
        },
    },
});

const selectorFiltersLoading = (state: RootState) => {
    return state.gerenciadorPrecosReducer.filters.loading;
};

const { SET_FILTERS_LOADING, RESET_FILTERS_LOADING } = slice.actions;

export {
    initialFiltersLoadingState,
    RESET_FILTERS_LOADING,
    selectorFiltersLoading,
    SET_FILTERS_LOADING,
};
export default slice.reducer;
