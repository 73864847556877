/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { MdSearch } from 'react-icons/md';
import { ButtonSecondary } from '../../ButtonSecondary';
import { FilterMenuSearchBar } from '../FilterMenuSearchBar';
import styles from './FilterMenuSearchWrapper.module.scss';

export type FilterMenuSearchWrapperProps = {
    search: string;
    handleSearch?: (value: string, event: React.SyntheticEvent<any>) => void;
    handleOpenModal: () => void;
};

export const FilterMenuSearchWrapper = ({
    search,
    handleSearch,
    handleOpenModal,
}: FilterMenuSearchWrapperProps) => {
    return (
        <div className={styles.wrapper}>
            <label className={styles['search-container']} htmlFor="search-bar">
                <FilterMenuSearchBar
                    id="search-bar"
                    className={styles.search}
                    value={search}
                    onSearch={handleSearch}
                />
                <MdSearch className={styles.icon} size={18} />
                <ButtonSecondary
                    className={styles.button}
                    onClick={handleOpenModal}
                    theme="blue"
                    size="sm"
                >
                    Colar lista de códigos
                </ButtonSecondary>
            </label>
        </div>
    );
};
