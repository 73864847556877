import { z } from "zod";
import { CompetitorDaysData, SetupIPADadosDeConcorrencia } from "../SetupIPADadosDeConcorrenciatypes";


export interface ConfigFormProps {
    data?: SetupIPADadosDeConcorrencia;
    createConfig: (data: CompetitorDaysData) => void;
    updateConfig: (data: CompetitorDaysData) => void;
}

export const schema = z.object({
    id: z.string().nullable().optional(),
    competitor_days: z.coerce
        .number()
        .min(1, 'Deve ser maior que 0')
        .max(180, 'Deve ser menor que 180'),
    suppliers_competitor_days: z.coerce
        .number()
        .min(1, 'Deve ser maior que 0')
        .max(180, 'Deve ser menor que 180'),
    competitor_data: z.object({
        price_option: z.string(),
        other_prices: z.array(
            z.string()
        ).optional(),
    }).optional(),
});

export type FormValues = z.infer<typeof schema>;
