import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import React from 'react';
import { Tooltip, Whisper } from 'rsuite';

import SimulacaoData from '../../../../../../../assets/image/simulacao-data.svg';

import moment from 'moment';
import { useSelector } from 'react-redux';
import IconQuery from '../../../../../../../assets/icons/icon_query.svg';
import { selectorDemandForecast } from '../../../../../../../reducers/previsaoDemanda';
import { BigNumbers } from '../BigNumbersArea/BigNumbers';

import styles from './ForecastDetails.module.scss';
export const ForecastDetails = () => {
    const { outputArea, filtersArea } = useSelector(selectorDemandForecast);
    const { hasSimulation, isSearchDone } = outputArea.calculations;
    const { fromDate } = outputArea.query;

    return (
        <main className={styles['forecast-details']}>
            {filtersArea.isCompleted && isSearchDone && !hasSimulation ? (
                <div className={styles['empty-state']}>
                    <WarningRoundedIcon
                        style={{ fontSize: '24px', color: '#e34850' }}
                    />
                    <p>
                        Não temos dados suficientes para calcular a elasticidade
                        desse produto.
                    </p>
                    <span>Busque outro produto ou loja.</span>
                </div>
            ) : !hasSimulation && !isSearchDone ? (
                <div className={styles['data-info']}>
                    <div className={styles['data-info-content']}>
                        <img src={SimulacaoData} alt="Simulação de dados" />
                        <p>
                            Para ver os resultados da simulação,
                            <br /> preencha os dados acima
                        </p>
                    </div>
                </div>
            ) : (
                <div className={styles['forecast-wrapper']}>
                    <div className={styles['forecast-volume-info']}>
                        <img alt="ícone de previsão" src={IconQuery} />
                        <h3>
                            Previsão de Demanda de Volume e Receita{' '}
                            <Whisper
                                trigger="hover"
                                placement="top"
                                speaker={
                                    <Tooltip>
                                        <p>
                                            Essa previsão tem margem de erro de
                                            aproximadamente{' '}
                                            <strong>40% </strong>
                                        </p>
                                        Estimativas de demanda estão sujeitas a
                                        erros, visto que o preço não é o único
                                        fator de influência do consumo
                                    </Tooltip>
                                }
                            >
                                <span className={styles['beta-badge']}>
                                    BETA
                                </span>
                            </Whisper>
                        </h3>
                        <p className={styles['date']}>
                            Data do cálculo:{' '}
                            <span>{moment(fromDate).format('DD/MM/yyyy')}</span>
                        </p>
                    </div>
                    <BigNumbers />
                </div>
            )}
        </main>
    );
};
