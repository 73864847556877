import React from 'react';
import { Controller } from 'react-hook-form';
import { InputBox } from '../../../../../../components';
import styles from '../SetupIPADadosDeConcorrencia.module.scss';
import { ControlReactHookForm } from '../SetupIPADadosDeConcorrenciatypes';

const CompetitorDaysInput = ({ control }: ControlReactHookForm) => (
    <div className={styles['wrapper-dias-concorrente']}>
        <Controller
            name="competitor_days"
            control={control}
            render={({ field, fieldState: { error } }) => (
                <InputBox className={styles['input-box']}>
                    <InputBox.Number {...field} min={1} max={180} />
                    <InputBox.Error message={error?.message} />
                </InputBox>
            )}
        />
        <span>Máximo de 180 dias</span>
    </div>
);

export default CompetitorDaysInput;
