/* eslint-disable max-len */
export const OPTIONS = {
    price_option: {
        options: [
            {
                key: 'option_1',
                value: 'REGULAR',
                title: 'Usar somente o preço regular',
                subtitle: 'Será considerado no IPA somente o preço regular dos concorrentes. Nesta opção, os preço promocionais dos concorrentes não entram no IPA. Produtos em que temos a informação de preço regular e oferta (De / Por) será considerado o preço regular.',
            },
            {
                key: 'option_2',
                value: 'REGULAR_AND_OTHERS',
                title: 'Usar preço regular e outros tipos de preços',
                subtitle: 'Serão considerados no IPA o preço regular e, sempre que disponíveis, outros tipos de preços dos concorrentes. Nesta opção, se houver outros tipos de preços além do regular, serão priorizados os preços com algum tipo de desconto. Você pode escolher quais preços deseja considerar.',
            },
        ],
    },
} as const;

export const CHEBOX_OPTIONS = {
    other_prices: {
        options: [
            {
                key: 'option_1',
                value: "REGULAR_PRICE",
                title: 'Preço regular',
                subtitle: "O preço regular sempre será usado quando não tiver o preço promocional."
            },
            {
                key: 'option_2',
                value: "PROMOTIONAL_PRICE_FROM_BY",
                title: 'Preço promocional: De / Por',
                subtitle: "Usar o preço Por em dinâmicas De / Por, ou seja, preços promocionais que demonstram claramente dois preços, sendo o De o preço regular regular e o Por, o preço que será efetivamente pago pelo comprador. "
            },
            {
                key: 'option_3',
                value: "PROMOTIONAL_PRICE_FEATURED_PRODUCT",
                title: 'Preço promocional: Produto em destaque',
                subtitle: "Usar o preço promocional de produto em destaque, ou seja, produtos que são destacados no ponto de venda, para chamar a atenção do comprador que existe uma política diferenciada para aquele produto."
            },
            {
                key: 'option_4',
                value: "PROMOTIONAL_PRICE_OTHER_OFFERS",
                title: 'Preço promocional: Outras ofertas',
                subtitle: "Usar o preço promocional de outras ofertas, ou seja, preços promocionais, mas classificados de forma diferente das demais condições listadas."
            },
            {
                key: 'option_5',
                value: "LOWER_PRICE",
                title: 'Rebaixa de preço',
                subtitle: "Usar o preço em rebaixa, ou seja, preços identificados como possível rebaixa de preços analisando histórico do preço. "
            },
            {
                key: 'option_6',
                value: "PROMOTIONAL_PRICE_OFFER",
                title: 'Preço promocional: Oferta acima de 1 unidade',
                subtitle: "Usar o preço de oferta acima de 1 unidade, ou seja, preços promocionais condicionados a uma quantidade mínima a ser adquirida no ato da compra."
            },
            {
                key: 'option_7',
                value: "PROMOTIONAL_PRICE",
                title: 'Preço promocional: Produtos próximo a data de vencimento',
                subtitle: "Usar o preço promocional de produtos próximo do vencimento, ou seja, preços promocionais que são indicados como produtos próximos a data de vencimento"
            },
            {
                key: 'option_8',
                value: "DISCOUNT_CLUB",
                title: 'Clube de desconto',
                subtitle: "Usar o preço promocional de produto que fazem parte de clube de desconto, ou seja, produtos que têm algum tipo de oferta exclusivo para clube de desconto"
            }
        ]
    }
}
