import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataItem } from '../../../@types/DataItem';
import { RootState } from '../../../@types/RootState';
import { Exception } from '../../../data';
import { useDebounce } from '../../../hooks';
import {
    SET_FILTER_CACHE_BY_KEY,
    SET_FILTER_DATA_BY_KEY,
    SET_FILTERS_SEARCH_BY_KEY,
    SET_FILTERS_VALUE_BY_KEY,
} from '../../../reducers';
import { checkIfIsLoading } from '../../../utils';
import { CheckPicker } from '../../CheckPicker';
import { FilterPlaceholder } from '../../FilterPlaceholder';
import { listProducts } from '../../FilterSection/services';

const DATA_KEY = 'productIds' as const;

export const ProductsPill = () => {
    const dispatch = useDispatch();
    const debounce = useDebounce(500);

    const [hasPastedValues, setHasPastedValues] = useState(false);

    const data = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.filters.data.productIds ?? [];
    });

    const cache = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.filters.cache.productIds ?? [];
    });

    const value = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.filters.values.productIds ?? [];
    });

    const query = useSelector((state: RootState) => {
        return state.gerenciadorPrecosReducer.filters.search.productIds ?? '';
    });

    const handleGetProducts = useCallback(async () => {
        try {
            const options = { isModal: hasPastedValues };
            return await listProducts(query, options);
        } catch {
            setHasPastedValues(false);
            throw new Exception('Erro ao buscar produtos');
        }
    }, [hasPastedValues, query]);

    const handleSuccess = (data: DataItem[]) => {
        const dataWithCache = [...(cache ?? []), ...data];
        const uniqueData = _.uniqBy(dataWithCache, 'value');
        dispatch(SET_FILTER_DATA_BY_KEY({ key: DATA_KEY, data: uniqueData }));
        if (!hasPastedValues) return;
        const value = uniqueData.map(({ value }) => value);
        dispatch(SET_FILTERS_VALUE_BY_KEY({ key: DATA_KEY, value }));
        dispatch(SET_FILTER_CACHE_BY_KEY({ key: DATA_KEY, cache: uniqueData }));
        setHasPastedValues(false);
    };

    const { fetchStatus } = useQuery({
        queryKey: [`ipa.filter.productIds`, query],
        initialData: [],
        queryFn: handleGetProducts,
        onSuccess: handleSuccess,
    });

    const isLoading = useMemo(
        () => checkIfIsLoading(fetchStatus),
        [fetchStatus],
    );

    const handleSearch = useCallback(
        (query: string) => {
            const payload = { key: DATA_KEY, query } as const;
            debounce(() => dispatch(SET_FILTERS_SEARCH_BY_KEY(payload)));
        },
        [dispatch, debounce],
    );

    const handleUpdateCache = useCallback(
        (values: string[]) => {
            const cache = data.filter((item) => values.includes(item.value));
            const payload = { key: DATA_KEY, cache } as const;
            if (cache) dispatch(SET_FILTER_CACHE_BY_KEY(payload));
        },
        [dispatch, data],
    );

    const handleChange = useCallback(
        (values: string[]) => {
            const payload = { key: DATA_KEY, value: values } as const;
            dispatch(SET_FILTERS_VALUE_BY_KEY(payload));
            handleUpdateCache(values);
        },
        [dispatch, handleUpdateCache],
    );

    const handleClose = useCallback(() => handleSearch(''), [handleSearch]);

    const handleClean = useCallback(() => {
        dispatch(SET_FILTERS_SEARCH_BY_KEY({ key: DATA_KEY, query: '' }));
        dispatch(SET_FILTERS_VALUE_BY_KEY({ key: DATA_KEY, value: [] }));
        dispatch(SET_FILTER_CACHE_BY_KEY({ key: DATA_KEY, cache: [] }));
    }, [dispatch]);

    const handleConfirmPasteValues = useCallback(
        (query: string) => {
            if (!query.length) return;
            handleSearch(query);
            setHasPastedValues(true);
        },
        [handleSearch],
    );

    return (
        <CheckPicker
            data={data}
            value={value}
            search={query}
            isLoading={isLoading}
            onSearch={handleSearch}
            onChange={handleChange}
            onClose={handleClose}
            onClean={handleClean}
            // @ts-ignore
            placeholder={
                <FilterPlaceholder
                    key="productIds"
                    dataKey="productIds"
                    placeholder="Produtos"
                />
            }
            pasteValues
            onConfirmPasteValues={handleConfirmPasteValues}
        />
    );
};
