import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'rsuite';
import { RootState } from '../../../../../../@types/RootState';
import { checkIfIsLoading } from '../../../../../../utils';
import { postSetupIPADadosDeConcorrencia, upadateSetupIPADadosDeConcorrencia } from '../services';

const QUERY_KEY = ['ipa/setup-form/competitor-days'];

export const useSetupIPAConfig = () => {
    const queryClient = useQueryClient();

    const data = useSelector((state: RootState) => {
        return state.reducerIPA.basicConfig.competitorsDays;
    });

    const queryState = useMemo(
        () => queryClient.getQueryState(QUERY_KEY),
        [queryClient],
    );

    const { mutateAsync: createConfig } = useMutation({
        mutationFn: postSetupIPADadosDeConcorrencia,
        onSuccess: () => {
            Alert.success('Configurações salvas com sucesso');
            queryClient.invalidateQueries(['ipa/setup-form/competitor-days']);
        },
        onError: () => {
            Alert.error('Erro ao salvar as configurações');
        },
    });

    const { mutateAsync: updateConfig } = useMutation({
        mutationFn: upadateSetupIPADadosDeConcorrencia,
        onSuccess: () => {
            Alert.success('Configurações salvas com sucesso');
            queryClient.invalidateQueries(['ipa/setup-form/competitor-days']);
        },
        onError: () => {
            Alert.error('Erro ao salvar as configurações');
        },
    });

    return {
        data,
        error: queryState?.error,
        isLoading: checkIfIsLoading(queryState?.fetchStatus),
        createConfig,
        updateConfig,
    };
};
