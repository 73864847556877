import React, { ComponentProps, forwardRef } from 'react';
import styles from './FilterMenutextArea.module.scss';

export type FilterMenutextAreaProps = ComponentProps<'textarea'>;

const FilterMenutextArea = forwardRef<
    HTMLTextAreaElement,
    FilterMenutextAreaProps
>(({ ...props }, ref) => {
    return <textarea ref={ref} className={styles.textarea} {...props} />;
});

FilterMenutextArea.displayName = 'FilterMenutextArea';

export { FilterMenutextArea };
