import { combineReducers } from '@reduxjs/toolkit';
import demandForecastSavedScenariosSlice, {
    DemandForecastSimulationsProps,
} from './demandForecastSimulations';
import filterAreaSlice, { FiltersStateProps } from './filterArea';
import inputsSlice, { InputStateProps } from './inputArea';
import outputSlice, { OutputAreaProps } from './outputArea';
import outputSavedSimulationsSlice, {
    OutputSavedSimulationProps,
} from './outputSavedSimulations';

export type DemandForecastStateProps = {
    simulatorArea: InputStateProps;
    filtersArea: FiltersStateProps;
    outputArea: OutputAreaProps;
    outputSavedSimulations: OutputSavedSimulationProps;
    demandForecastSavedScenarios: DemandForecastSimulationsProps;
};

const rootReducer = combineReducers({
    simulatorArea: inputsSlice,
    filtersArea: filterAreaSlice,
    outputArea: outputSlice,
    outputSavedSimulations: outputSavedSimulationsSlice,
    demandForecastSavedScenarios: demandForecastSavedScenariosSlice,
});

export const selectorDemandForecast = (
    state: Record<'demandForecastReducer', DemandForecastStateProps>,
) => state.demandForecastReducer;

export default rootReducer;
