import React from "react";
import { Callout } from "../../../../../../components";
import styles from "../SetupFarma.module.scss";

export const SetupFarmaCallout = () => (
    <div className={styles['setup-farma__main-config__footer']}>
        <Callout variant="warning" size="sm">
            As alterações são salvas automaticamente e serão aplicadas na próxima integração de dados.
        </Callout>
    </div>
);
