import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { snakeCaseToCamelCase } from '../../utils/snakeCaseToCamelCase';

export type Input = {
    competitiveness: number;
    margin: number;
    value: number;
    newPrice: number;
};

export type InputStateProps = {
    inputs: Input;
};

const initialState: InputStateProps = {
    inputs: {
        competitiveness: 0,
        margin: 0,
        value: 0,
        newPrice: 0,
    },
};

const slice = createSlice({
    name: 'demand-forecast-input-area',
    initialState,
    reducers: {
        setInputsValue(
            state,
            {
                payload,
            }: PayloadAction<{
                inputs: Input;
            }>,
        ) {
            state.inputs = snakeCaseToCamelCase(payload.inputs);
        },
        setNewValue(
            state,
            {
                payload,
            }: PayloadAction<{
                newprice: number;
            }>,
        ) {
            state.inputs = {
                ...state.inputs,
                newPrice: payload.newprice,
            };
        },
        clearInputData: () => initialState,
    },
});

export const { setInputsValue, setNewValue, clearInputData } = slice.actions;

export default slice.reducer;
