import classNames from 'classnames';
import React from 'react';
import { Modal } from 'rsuite';
import { ModalProps } from 'rsuite/lib/Modal';
import { User } from '../../@types';
import gif from '../../assets/image/ipa-banner-lead.gif';
import { ReactComponent as Logo } from '../../assets/logo/logo-ipa-branco.svg';
import { sendLead } from '../../pages/ISA/InfoPanel/InfoPanel/utils';
import { trackEvent } from '../../utils/MatomoConfig';
import styles from './ModalIPALead.module.scss';

type ModalIPALeadProps = ModalProps & {
    user?: User;
};

const ModalIPALead = ({ user, className, ...props }: ModalIPALeadProps) => {
    const handleClick = async (e: React.MouseEvent<Element, MouseEvent>) => {
        // eslint-disable-next-line no-alert
        const confirm = window.confirm(
            'Deseja entrar em contato com a nossa equipe?',
        );
        if (!confirm) return;
        await sendLead(user, 'IPA');
        trackEvent('Upsell/Crossell', 'IPA');
        window.open(
            'https://wa.me/551137779670?text=Ol%C3%A1%21+Quero+saber+mais+sobre+o+IPA+Software+de+Precifica%C3%A7%C3%A3o%21',
        );
        props.onHide?.(e);
    };

    return (
        <Modal className={classNames(styles.modal, className)} {...props}>
            <Modal.Body className={styles.modal__content}>
                <Logo className={styles.logo} />
                <h2 className={styles.title}>
                    Garanta uma precificação mais inteligente com a versão
                    completa do IPA
                </h2>
                <img
                    src={gif}
                    alt="Imagem animada demonstrando o funcionamento da aplicação"
                />
                <div className={styles['inner-container']}>
                    <h3 className={styles.subtitle}>
                        Tenha acesso total às funcionalidades que vão otimizar
                        ainda mais seus resultados com o IPA Pro.
                    </h3>
                    <ul>
                        <li>
                            Consultoria especializada para definição das
                            estratégias de precificação
                        </li>
                        <li>
                            Crie regras de precificação automática e defina
                            limites de margem ou competitividade
                        </li>
                        <li>
                            Preveja a sua demanda futura de maneira fácil Crie
                            campanhas promocionais, agrupadas por lojas e
                            produtos
                        </li>
                        <li>
                            Tenha visibilidade do desempenho de vendas,
                            competitividade e performance do seu negócio no
                            módulo de analytics
                        </li>
                    </ul>
                </div>
                <button onClick={handleClick}>fale com um especialista</button>
            </Modal.Body>
        </Modal>
    );
};

export default ModalIPALead;
