import { ArrowDownLine } from '@rsuite/icons';
import React, { useCallback, useRef, useState } from 'react';
import { DateRangePicker } from 'rsuite';

const DateRangePickerPillRaw = ({
    appearance,
    block,
    character,
    cleanable,
    container,
    defaultCalendarValue,
    defaultOpen,
    defaultValue,
    disabled,
    disabledDate,
    format,
    hoverRange,
    isoWeek,
    limitEndYear,
    locale,
    onChange,
    onClean,
    onClose,
    onEnter,
    onEntered,
    onEntering,
    onExit,
    onExited,
    onExiting,
    onOk,
    onOpen,
    onSelect,
    oneTap,
    open,
    placeholder,
    placement,
    preventOverflow,
    ranges,
    renderValue,
    showWeekNumbers,
    size,
    value,
    className,
    classNameValue,
    leftIcon,
}, ref) => {
    const [hasValue, setHasValue] = useState('');
    const arrowDownIconStyle = hasValue
        ? { fontSize: '14px', color: '#0D66D0' }
        : { fontSize: '14px' };

    const refDatepicker = useRef();

    const handleRenderValue = useCallback(
        (value) => {
            if (value.length) {
                setHasValue('date-range-has-value');
            }
            // eslint-disable-next-line no-unused-expressions
            onChange && onChange(value);
        },
        [onChange],
    );

    const handleOnClean = useCallback(
        (e) => {
            setHasValue('');
            // eslint-disable-next-line no-unused-expressions
            onClean && onClean(e);
        },
        [onClean],
    );

    React.useImperativeHandle(ref, () => {
        setHasValue('');
        return { handleClean: refDatepicker?.current?.handleClean };
    });

    return (
        <div
            className={`date-range-picker-pill-container ${
                hasValue || classNameValue
            }`}
        >
            <ArrowDownLine
                onClick={() => refDatepicker?.current?.open()}
                style={arrowDownIconStyle}
                className="date-range-picker-pill-container__arrow-down-icon"
            />
            {leftIcon ? <span className="left-icon">{leftIcon}</span> : null}
            <DateRangePicker
                id="date-range-picker"
                className={className}
                appearance={appearance}
                block={block}
                character={character}
                cleanable={cleanable}
                container={container}
                defaultCalendarValue={defaultCalendarValue}
                defaultOpen={defaultOpen}
                defaultValue={defaultValue}
                disabled={disabled}
                disabledDate={disabledDate}
                format={format}
                hoverRange={hoverRange}
                isoWeek={isoWeek}
                limitEndYear={limitEndYear}
                locale={locale}
                onChange={handleRenderValue}
                onClean={handleOnClean}
                onClose={onClose}
                onEnter={onEnter}
                onEntered={onEntered}
                onEntering={onEntering}
                onExit={onExit}
                onExited={onExited}
                onExiting={onExiting}
                onOk={onOk}
                onOpen={onOpen}
                onSelect={onSelect}
                oneTap={oneTap}
                open={open}
                placeholder={placeholder}
                placement={placement}
                preventOverflow={preventOverflow}
                ranges={ranges}
                renderValue={renderValue}
                showWeekNumbers={showWeekNumbers}
                size={size}
                value={value}
                ref={refDatepicker}
            />
        </div>
    );
};
const DateRangePickerPill = React.forwardRef(DateRangePickerPillRaw);
export default DateRangePickerPill;
