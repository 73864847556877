import React from "react";
import styles from "../SetupFarma.module.scss";

export const SetupFarmaHeader = () => (
    <div className={styles['setup-farma-config']}>
        <h3>Farma</h3>
        <p className={styles['setup-farma-config__header__subtitle']}>
            Ative as funções desenvolvidas para o varejo farmaceutico
        </p>
    </div>
);
