import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'rsuite';
import { selectorDemandForecast } from '../../../../../../reducers/previsaoDemanda';
import { Input } from '../../../../../../reducers/previsaoDemanda/inputArea';
import {
    Scenario,
    setOutputs,
} from '../../../../../../reducers/previsaoDemanda/outputArea';
import {
    handleModal,
    setScenario,
} from '../../../../../../reducers/previsaoDemanda/outputSavedSimulations';
import { camelCaseToSnakeCase } from '../../../../../../utils/camelCaseToSnakeCase';
import { getDemandForecastResult } from '../../services';
import { Input as InputType, Scenario as ScenarioType } from '../@types/output';
import {
    demandForecastDeleteScenario,
    demandForecastDownloadScenario,
    demandForecastSaveScenario,
} from '../services';

type InputProps = InputType & {
    value: number;
};
export const useHandleScenarios = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const {
        outputSavedSimulations,
        simulatorArea,
        filtersArea,
        outputArea,
        demandForecastSavedScenarios,
    } = useSelector(selectorDemandForecast);

    const { modal } = outputSavedSimulations;

    const disabled = !demandForecastSavedScenarios.calculations.hasSomeChecked;

    const disabledMainButton = !filtersArea.isCompleted;

    const simulationsCount =
        demandForecastSavedScenarios.savedScenarios.list?.length || 0;

    const handleDeleteScenario = async () => {
        const ids = demandForecastSavedScenarios.scenariosChecked.map(
            (scenario) => scenario.id,
        );
        await demandForecastDeleteScenario(ids);

        showMessageAndCloseModal('Cenário excluído com sucesso!');
        queryClient.invalidateQueries(['saved-scenarios']);
    };

    const generateScenarioName = () => {
        const { username, date } = outputArea.scenario;
        const { productName, storeIds } = outputArea.query;

        const storeName =
            filtersArea.list.stores.find((s) => s.store_id === storeIds[0])
                ?.label || '';

        const savedAt = new Date(date ? `${date}T00:00:00.175` : 0);

        const title = `${productName} - ${storeName}`;
        const subtitle = `Cenário salvo por ${username}, ${format(savedAt, 'dd/MM/yyyy')}`;

        return {
            title,
            subtitle,
        };
    };

    const payloadToSaveOrUpdateScenario = (isUpdate = false) => {
        const input = camelCaseToSnakeCase<Input, InputProps>(
            simulatorArea.inputs,
        );

        const { query, output, simulation } = camelCaseToSnakeCase(outputArea);

        const scenario = camelCaseToSnakeCase<Scenario, ScenarioType>(
            outputSavedSimulations.scenario,
        );

        const queryMap = {
            ...query,
            product_name: filtersArea.activeFilter.productName,
        };

        const inputMap = {
            ...input,
            base_price: input.value,
        };

        const scenarioMap = {
            ...scenario,
            id: isUpdate ? scenario.id : null,
        };

        return {
            query: queryMap,
            input: inputMap,
            output,
            scenario: scenarioMap,
            simulations: simulation,
        };
    };

    const handleSaveScenario = async () => {
        const { input, query, output, scenario, simulations } =
            payloadToSaveOrUpdateScenario(false);

        const response = await demandForecastSaveScenario({
            query,
            input,
            output,
            scenario,
            simulations,
        });

        if (response) {
            showMessageAndCloseModal('Cenário salvo com sucesso!');
        }
    };

    const handleUpdateScenario = async () => {
        const { input, query, output, scenario, simulations } =
            payloadToSaveOrUpdateScenario(true);

        const response = await demandForecastSaveScenario({
            query,
            input,
            output,
            scenario,
            simulations,
        });

        if (response) {
            showMessageAndCloseModal('Cenário salvo com sucesso!');
        }
    };

    const handleDownloadScenario = async () => {
        const input = camelCaseToSnakeCase<Input, InputProps>(
            simulatorArea.inputs,
        );

        const { query, output, simulation, scenario } =
            camelCaseToSnakeCase(outputArea);

        const queryMap = {
            ...query,
            product_name: filtersArea.activeFilter.productName,
        };

        const inputMap = {
            ...input,
            base_price: input.value,
        };

        const data = [
            {
                input: inputMap,
                output,
                query: queryMap,
                scenario,
                simulations: simulation,
            },
        ];

        const response = await demandForecastDownloadScenario(data);

        if (response) {
            downloadScenario(response, scenario.scenario_name);
        }
    };

    const handleDownloadSelectedScenarios = async () => {
        const scenarios =
            demandForecastSavedScenarios.savedScenarios.list.filter(
                (s) => s.checked,
            );
        const data = scenarios.map((scen) => {
            const input = camelCaseToSnakeCase<Input, InputProps>(scen.input);

            const { query, output, simulation, scenario } =
                camelCaseToSnakeCase(scen);

            const queryMap = {
                ...query,
                product_name: filtersArea.activeFilter.productName,
            };

            const inputMap = {
                competitiveness: input.competitiveness || null,
                new_price: input.new_price,
                margin: input.margin,
                base_price: input.value || input.base_price || null,
            };

            return {
                input: inputMap,
                output,
                query: queryMap,
                scenario,
                simulations: simulation,
            };
        });

        const response = await demandForecastDownloadScenario(data);

        if (response) {
            downloadScenario(response, 'Simulações');
        }
    };

    const downloadScenario = (data: BlobPart, name: string) => {
        const blob = new Blob([data]);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = window.URL.createObjectURL(blob);
        hiddenElement.target = '_blank';
        hiddenElement.download = `${name}.csv`;
        hiddenElement.click();
        return blob;
    };

    const showMessageAndCloseModal = (message: string) => {
        closeAllModals();
        Alert.success(message);
    };

    const handleGetDemandForecastSimulate = async () => {
        const { selectedFilters } = filtersArea;
        const { inputs } = simulatorArea;

        const response = await getDemandForecastResult({
            basePrice: inputs.value,
            competitiveness: inputs.competitiveness,
            margin: inputs.margin,
            productId: selectedFilters.productId,
            storeIds: selectedFilters.storesId,
            fromDate: moment(selectedFilters.dateRange[0]).format('YYYY-MM-DD'),
            toDate: moment(selectedFilters.dateRange[1]).format('YYYY-MM-DD'),

            newPrice: String(inputs.newPrice).includes(',')
                ? String(inputs.newPrice).replace(',', '.')
                : inputs.newPrice,
        });
        if (response) {
            const query = {
                ...response.query,
                product_name: filtersArea.activeFilter.productName,
            };

            dispatch(
                setOutputs({
                    simulation: response.simulations,
                    output: response.output,
                    query: query,
                    scenario: response.scenario,
                }),
            );
        }
    };

    const handleShowModal = (id?: string) => {
        const { title } = generateScenarioName();
        dispatch(
            setScenario({
                scenarioName: `${title}`,
            }),
        );
        if (id) {
            dispatch(handleModal({ modalType: 'showModalUpdate', open: true }));
        } else {
            dispatch(handleModal({ modalType: 'showModalSave', open: true }));
        }
    };

    const handleShowConfirmActionModal = () => {
        dispatch(
            handleModal({ modalType: 'showModalConfirmAction', open: true }),
        );
    };

    const closeAllModals = () => {
        dispatch(handleModal({ modalType: 'showModalSave', open: false }));
        dispatch(handleModal({ modalType: 'showModalUpdate', open: false }));
        dispatch(
            handleModal({ modalType: 'showModalConfirmAction', open: false }),
        );
    };

    const handleOpenModalSave = () => {
        dispatch(handleModal({ modalType: 'showModalSave', open: false }));
        dispatch(handleModal({ modalType: 'showModalSave', open: true }));
    };

    return {
        handleGetDemandForecastSimulate,
        closeAllModals,
        handleShowModal,
        handleSaveScenario,
        handleShowConfirmActionModal,
        handleDeleteScenario,
        handleDownloadScenario,
        handleUpdateScenario,
        generateScenarioName,
        handleOpenModalSave,
        handleDownloadSelectedScenarios,
        props: {
            disabled,
            disabledMainButton,
            simulationsCount,
            modal,
        },
    };
};
