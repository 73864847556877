import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Alert } from 'rsuite';
import { queryClient } from '../../../../../App';
import { ButtonPrimary, Callout, InputBox } from '../../../../../components';
import {
    initialState,
    PrecoAtacadoSchema,
    schemaSetupWholesale,
    selectorSetupWholesale,
} from '../../../../../reducers/reducerIPA/wholesale';
import containerStyles from '../SetupIPA/SetupIPA.module.scss';
import { SetupIPAOption } from '../SetupIPA/elements';
import styles from './SetupIPAPrecoAtacado.module.scss';
import { PUT_SETUP_IPA_PRECO_ATACADO } from './services';

const OPTIONS = {
    allowPriceRound: {
        title: 'preço psicológico',
        subtitle:
            'Defina se o preço de atacado deve ser arredondado segundo o preço psicológico ou não',
        options: [
            {
                key: 'option_1',
                value: true,
                children:
                    'Arredondar o preço de atacado usando preço psicológico',
            },
            {
                key: 'option_2',
                value: false,
                children: 'Manter o preço de atacado sem arredondamento',
            },
        ],
    },
    priceEdit: {
        title: 'edição no gerenciador de preços',
        subtitle:
            'Escolha se os usuários poderão ou não editar o preço de atacado sugerido no gerenciador de preços e o gatilho de ativação.',
        options: [
            {
                name: 'allowPriceEdit',
                title: 'Permitir a edição do preço de atacado',
                subtitle:
                    'Essa opção possibilita que o usuário altere o preço de atacado sugerido, seja pelo valor, pela margem ou pela competitividade.',
            },
            {
                name: 'allowTriggerEdit',
                title: 'Permitir a edição do gatilho de ativação do preço de atacado',
                subtitle:
                    'Possibilita a edição do gatilho que ativa o preço de atacado diretamente na tela de gerenciador de preços.',
            },
        ],
    },
} as const;

type SetupIPAPrecoAtacadoProps = React.FormHTMLAttributes<HTMLFormElement>;

const SetupIPAPrecoAtacado = ({
    className,
    ...props
}: SetupIPAPrecoAtacadoProps) => {
    const values = useSelector(selectorSetupWholesale);

    const { mutateAsync } = useMutation({
        mutationFn: PUT_SETUP_IPA_PRECO_ATACADO,
        onSuccess: () => {
            queryClient.invalidateQueries(['ipa/setup-form/wholesale']);
        },
    });

    const form = useForm<PrecoAtacadoSchema>({
        values: values || initialState,
        resolver: zodResolver(schemaSetupWholesale),
    });

    const handleSubmit = async (formData: PrecoAtacadoSchema) => {
        try {
            await mutateAsync(formData);
        } catch (err) {
            if (err instanceof Error) {
                Alert.error(err.message);
            }
        }
    };

    return (
        <form
            className={classNames(styles.form, className)}
            onSubmit={form.handleSubmit(handleSubmit)}
            {...props}
        >
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <h3 className={containerStyles['setup-ipa__header__title']}>
                        Preço Atacado
                    </h3>
                    <p
                        className={
                            containerStyles['setup-ipa__header__subtitle']
                        }
                    >
                        Defina as configurações para a precificação no atacado
                    </p>
                </div>

                <SetupIPAOption
                    title={OPTIONS.allowPriceRound.title}
                    subtitle={OPTIONS.allowPriceRound.subtitle}
                    className={styles['option-radio']}
                >
                   {/*  <Controller
                        control={form.control}
                        name="allowPriceRound"
                        render={({ field, fieldState: { error } }) => (
                            <InputBox>
                                <InputBox.RadioGroup {...field}>
                                    {OPTIONS.allowPriceRound.options.map(
                                        (option) => (
                                            <InputBox.Radio {...option} />
                                        ),
                                    )}
                                </InputBox.RadioGroup>
                                <InputBox.Error message={error?.message} />
                            </InputBox>
                        )}
                    /> */}
                </SetupIPAOption>

                <SetupIPAOption
                    title={OPTIONS.priceEdit.title}
                    subtitle={OPTIONS.priceEdit.subtitle}
                    className={styles['option-check']}
                    contentClassName={styles['option-check__content']}
                >
                    {OPTIONS.priceEdit.options.map((option) => (
                        <Controller
                            control={form.control}
                            name={option.name}
                            render={({ field, fieldState: { error } }) => (
                                <InputBox>
                                    <InputBox.Check
                                        checked={field.value}
                                        {...field}
                                    >
                                        <h5>{option.title}</h5>
                                        <p>{option.subtitle}</p>
                                    </InputBox.Check>
                                    <InputBox.Error message={error?.message} />
                                </InputBox>
                            )}
                        />
                    ))}
                </SetupIPAOption>
            </div>

            <div>
                <Callout size="sm">
                    <Callout.Description>
                        As modificações serão aplicadas na próxima integração de
                        dados.
                    </Callout.Description>
                </Callout>
            </div>

            <div className={styles.footer}>
                <ButtonPrimary
                    type="reset"
                    theme="ghost"
                    onClick={() => form.reset()}
                >
                    cancelar
                </ButtonPrimary>
                <ButtonPrimary
                    type="submit"
                    disabled={form.formState.isSubmitting}
                >
                    salvar
                </ButtonPrimary>
            </div>
        </form>
    );
};

export default SetupIPAPrecoAtacado;
