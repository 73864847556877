import qs from 'qs';
import { trackPromise } from 'react-promise-tracker';
import { clientId } from '../lib';
import api from '../utils/API';

const MAX_QUERY_SIZE = 1000;
const DEFAULT_QUERY_SIZE = 20;

export const getFamiliaPrecificacao = (productFamilyId) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/products/families/id`,
            method: 'GET',
            expectToken: true,
            params: {
                size: 10,
                ...(productFamilyId && {
                    productFamilyId,
                }),
            },
        }).then((resp) => resp?.data),
    );

export const getProdutoCliente = (query) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_CADASTROS_API_URL}/contratos/clientes/produtos/search`,
            method: 'GET',
            params: {
                query: query.query,
            },
            expectToken: true,
        }).then((resp) => resp?.data),
        'produto-cliente-service',
    );

export const getSegmentos = () =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/setup/categories`,
            params: {
                clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            },
            expectToken: true,
            method: 'GET',
        }).then((resp) => resp.data),
    );

export const getSegmentosClasses = () =>
    api({
        url: `${process.env.REACT_APP_IPA_URL}/setup/categories/classes`,
        method: 'GET',
        expectToken: true,
        params: {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        },
    }).then((resp) => resp.data);

export const getSegmentosSensibilities = () =>
    api({
        url: `${process.env.REACT_APP_IPA_URL}/setup/categories/sensibilities`,
        method: 'GET',
        expectToken: true,
        params: {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        },
    }).then((resp) => resp.data);

export const getItensSegmentoProduto = (nivel, params = {}) =>
    api({
        url: `${process.env.REACT_APP_IPA_URL}/products/filters`,
        method: 'GET',
        expectToken: true,
        params: {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
            size: params.size ? params.size : 20,
            field: nivel || null,
            ...(params.query && { query: params.query }),
            ...(params.categoryLevel1 && {
                categoryLevel1: params.categoryLevel1,
            }),
            ...(params.categoryLevel2 && {
                categoryLevel2: params.categoryLevel2,
            }),
            ...(params.categoryLevel3 && {
                categoryLevel3: params.categoryLevel3,
            }),
            ...(params.categoryLevel4 && {
                categoryLevel4: params.categoryLevel4,
            }),
            ...(params.categoryLevel6 && {
                categoryLevel6: params.categoryLevel6,
            }),
            ...(params.categoryLevel7 && {
                categoryLevel7: params.categoryLevel7,
            }),
            ...(params.description && { description: params.description }),
            ...(params.productId && { productId: params.productId }),
            ...(params.gtin && { gtin: params.gtin }),
            ...(params.abcClass && { abcClass: params.abcClass }),
        },
        paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    }).then((resp) => resp.data);

export const searchFiltroProdutos = ({
    query = '',
    size = DEFAULT_QUERY_SIZE,
    isModal = false,
    ...rest
}) => {
    return api({
        url: `${process.env.REACT_APP_IPA_URL}/products/search`,
        method: 'GET',
        expectToken: true,
        params: {
            clientId,
            query,
            size: isModal ? MAX_QUERY_SIZE : size,
            isModal,
            ...rest,
        },
    }).then((resp) => resp.data);
};

export const getQtdProdutosAfetados = (query) =>
    api({
        url: 'http://54.208.158.73:8080/produtos/qtd',
        method: 'POST',
        data: JSON.stringify({
            nivel_1:
                query && query.nivel_1 && query.nivel_1.length
                    ? query.nivel_1
                    : null,
            nivel_2:
                query && query.nivel_2 && query.nivel_2.length
                    ? query.nivel_2
                    : null,
            nivel_3:
                query && query.nivel_3 && query.nivel_3.length
                    ? query.nivel_3
                    : null,
            nivel_4:
                query && query.nivel_4 && query.nivel_4.length
                    ? query.nivel_4
                    : null,
            nivel_5:
                query && query.nivel_5 && query.nivel_5.length
                    ? query.nivel_5
                    : null,
            nivel_6:
                query && query.nivel_6 && query.nivel_6.length
                    ? query.nivel_6
                    : null,
            nivel_7:
                query && query.nivel_7 && query.nivel_7.length
                    ? query.nivel_7
                    : null,
            nivel_8:
                query && query.nivel_8 && query.nivel_8.length
                    ? query.nivel_8
                    : null,
            nivel_9:
                query && query.nivel_9 && query.nivel_9.length
                    ? query.nivel_9
                    : null,
        }),
    }).then((resp) => resp.data);

export const getLastUpdate = () =>
    api({
        url: `${process.env.REACT_APP_IPA_URL}/products/last-update`,
        method: 'GET',
        expectToken: true,
        params: {
            clientId: JSON.parse(localStorage.getItem('cliente'))?.codigo,
        },
    }).then((resp) => resp.data);

export const getCompetitorsPrices = (productId, clientId, storeId) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/competitor/prices/${productId}`,
            params: {
                clientId:
                    clientId ||
                    JSON.parse(localStorage.getItem('cliente'))?.codigo,
                storeId: storeId || 1,
            },
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp.data),
    );
