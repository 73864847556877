
const addPeriods = (
    value: string,
): string => value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');


const formatToBRL = (
    value: number | string,
): string => {
    const number = Number(value).toFixed(2).replace('.', ',');
    return 'R$ ' + addPeriods(number);
};

export default formatToBRL;
