import { trackPromise } from 'react-promise-tracker';
import api from '../../../../../utils/API';
import {
    SaveScenarioPayload,
    SearchScenarioParams,
    SearchScenarioPayload,
} from './@types/scenario-payload';

export const demandForecastSaveScenario = (data: SaveScenarioPayload) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/demand-forecasts`,
            method: 'POST',
            expectToken: true,
            data: { ...data },
        }).then((resp) => resp.data),
    );

export const demandForecastSearchScenario = (
    params?: SearchScenarioParams,
    data?: SearchScenarioPayload,
) =>
    trackPromise(
        api({
            // eslint-disable-next-line max-len
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/demand-forecasts/search?page=${params?.page || 0}&size=${params?.size || 10}&sort=${params?.sort || ''},${params?.order || 'asc'}`,
            method: 'POST',
            expectToken: true,
            data: data ? { ...data } : {},
        }).then((resp) => resp.data),
    );

export const demandForecastDownloadScenario = (data: SaveScenarioPayload[]) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/demand-forecasts/download`,
            method: 'POST',
            download: true,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'text/csv; charset=utf-8',
            },
            expectToken: true,
            data: data,
        }).then((resp) => resp.data),
    );

export const demandForecastGetScenarioById = (id: string) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/demand-forecasts/${id}`,
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp.data),
    );

export const demandForecastDeleteScenarioById = (id: string) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/demand-forecasts/${id}`,
            method: 'DELETE',
            expectToken: true,
        }).then((resp) => resp.data),
    );

export const demandForecastDeleteScenario = (ids: string[]) =>
    trackPromise(
        api({
            url: `${process.env.REACT_APP_DEMAND_FORECAST}/v2/demand-forecasts/`,
            method: 'DELETE',
            expectToken: true,
            params: { id: ids.join(',') },
        }).then((resp) => resp.data),
    );

type params = {
    query?: string[];
};
export const getStores = ({ query }: params) => {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/products/store`,
            method: 'GET',
            expectToken: true,
            params: {
                size: 20,
                ...(query?.length && {
                    search: query.join(),
                }),
            },
        }).then((resp) => resp.data),
    );
};

export const getStoresById = (ids: string[]) => {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/products/store`,
            method: 'GET',
            expectToken: true,
            params: {
                size: 20,
                ...(ids?.length && {
                    ids: ids.join(),
                }),
            },
        }).then((resp) => resp.data),
    );
};
