import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Content, FlexboxGrid } from 'rsuite';
import IconQuery from '../../../assets/icons/icon_query.svg';

const SimulacoesAnalises = ({ history }) => (
    <Container className="main-container simulacoes-analises-page">
        <FlexboxGrid>
            <FlexboxGrid.Item colspan={24}>
                <Content className="no-margin-bottom page-header">
                    <h2>Simulações e Análises</h2>
                </Content>
            </FlexboxGrid.Item>

            {/* <FlexboxGrid.Item colspan={12}>
                <Content className="no-margin-bottom item-card disabled">
                    <FlexboxGrid>
                        <FlexboxGrid.Item>
                            <img alt="ícone de cenários de margem" src={IconPercent} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={20}>
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={24}>
                                    <h3>
                                        Cenários Baseados em Margem
                                        {' '}
                                        <Badge
                                            content="EM BREVE"
                                            style={{ background: '#2680EB' }}
                                        />
                                    </h3>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={24}>
                                    <p>Simule cenários usando a margem</p>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
            </FlexboxGrid.Item> */}
            {/* <FlexboxGrid.Item colspan={12}>
                <Content className="no-margin-right item-card disabled">
                    <FlexboxGrid>
                        <FlexboxGrid.Item>
                            <img alt="ícone de cenários de competitividade" src={IconEvents} />
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={20}>
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={24}>
                                    <h3>
                                        Cenários Baseados em Competitividade
                                        {' '}
                                        <Badge
                                            content="EM BREVE"
                                            style={{ background: '#2680EB' }}
                                        />
                                    </h3>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={24}>
                                    <p>
                                        Simule cenários usando a competitividade
                                        {' '}
                                    </p>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
            </FlexboxGrid.Item> */}
        </FlexboxGrid>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 1 }}>
                <Content
                    className="item-card"
                    onClick={() =>
                        history.push(
                            '/ipa/simulacoes-analises/previsao-demanda',
                        )
                    }
                >
                    <div>
                        <div>
                            <img alt="ícone de previsão" src={IconQuery} />
                        </div>
                        <div>
                            <div>
                                <div>
                                    <h3>Previsão de Demanda</h3>
                                </div>
                                <div>
                                    <p>
                                        Faça a previsão de volume e receita de
                                        produtos selecionados
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Content>
            </div>

            <div style={{ flex: 1 }}>
                <Content
                    className="item-card"
                    onClick={() =>
                        history.push(
                            '/ipa/simulacoes-analises/previsao-demanda-v2',
                        )
                    }
                >
                    <div>
                        <div>
                            <img alt="ícone de previsão" src={IconQuery} />
                        </div>
                        <div>
                            <div>
                                <div>
                                    <h3>Previsão de Demanda Por Lojas</h3>
                                </div>
                                <div>
                                    <p>
                                        Faça a previsão de volume e receita de
                                        produtos selecionados por lojas e
                                        período
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Content>
            </div>
        </div>
    </Container>
);

export default withRouter(SimulacoesAnalises);
