import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../@types/RootState';
import { SetupIPAConfiguracoesBasicas } from '../../../pages/Administrador/Produtos/Components/SetupIPAConfiguracoesBasicas/SetupIPAConfiguracoesBasicas.types';

type State = SetupIPAConfiguracoesBasicas | undefined;

const initialState: State = {} as State;

const slice = createSlice({
    name: 'ipa.setup.basicConfig.competitorsDays',
    initialState,
    reducers: {
        SET_IPA_SETUP_COMPETITORS_DAYS: (_state, action) => action.payload,
        RESET_IPA_SETUP_COMPETITORS_DAYS: () => initialState,
    },
});

export const {
    SET_IPA_SETUP_COMPETITORS_DAYS,
    RESET_IPA_SETUP_COMPETITORS_DAYS,
} = slice.actions;

export const selectorIpaSetupCompetitorsDays = (state: RootState) => {
    return state.reducerIPA.basicConfig.competitorsDays;
};

export default slice.reducer;
