import React from "react";
import { ButtonPrimary, Callout } from "../../../../../../components";
import styles from "../SetupIPADadosDeConcorrencia.module.scss";

export const FormFooter = () => (
    <>
        <div className={styles['setup-ipa__main-config__footer']}>
            <Callout variant="warning" size="sm">
                As modificações serão aplicadas na próxima integração de dados.
            </Callout>
        </div>
        <div className={styles.footer}>
            <ButtonPrimary type="reset" theme="ghost">
                cancelar
            </ButtonPrimary>
            <ButtonPrimary type="submit">salvar</ButtonPrimary>
        </div>
    </>
);
