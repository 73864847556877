type SnakeCaseObject = { [key: string]: any };

const toSnake = (str: string) =>
    str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export function camelCaseToSnakeCase<
    T extends SnakeCaseObject,
    R extends SnakeCaseObject,
>(obj: T): R {
    if (Array.isArray(obj)) {
        return obj.map((item) => camelCaseToSnakeCase(item)) as unknown as R;
    } else if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
            const camelKey = toSnake(key);
            (acc as any)[camelKey] = camelCaseToSnakeCase(obj[key]);
            return acc;
        }, {} as R);
    }
    return obj;
}
