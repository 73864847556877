type SnakeCaseObject = { [key: string]: any };

function toCamelCase(snakeCase: string): string {
    return snakeCase.replace(/(_\w)/g, (matches) => matches[1].toUpperCase());
}

export function snakeCaseToCamelCase<T extends SnakeCaseObject>(obj: T): T {
    if (Array.isArray(obj)) {
        return obj.map((item) => snakeCaseToCamelCase(item)) as unknown as T;
    } else if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
            const camelKey = toCamelCase(key);
            (acc as any)[camelKey] = snakeCaseToCamelCase(obj[key]);
            return acc;
        }, {} as T);
    }
    return obj;
}
