import React, { ReactNode } from 'react';
import {
    FilterMenuSearchWrapper,
    FilterMenuSearchWrapperProps,
} from '../FilterMenuSearchWrapper';
import styles from './FilterMenuWithPasteValuesButton.module.scss';

export type FilterMenuWithPasteValuesButtonProps =
    FilterMenuSearchWrapperProps & {
        menu: ReactNode;
    };

export const FilterMenuWithPasteValuesButton = ({
    search,
    menu,
    handleSearch,
    handleOpenModal,
}: FilterMenuWithPasteValuesButtonProps) => {
    return (
        <div className={styles.wrapper}>
            <FilterMenuSearchWrapper
                search={search}
                handleSearch={handleSearch}
                handleOpenModal={handleOpenModal}
            />
            <div>{menu}</div>
        </div>
    );
};
