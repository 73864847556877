const PRECISION = 0;
const DECIMAL_SEPARATOR = ',';
const THOUSAND_SEPARATOR = '.';

const defaultOptions: Required<MaskOptions> = {
    precision: PRECISION,
    decimalSeparator: DECIMAL_SEPARATOR,
    thousandSeparator: THOUSAND_SEPARATOR,
    allowNegative: true,
    allowEmpty: false,
};

export type MaskValue = string | number;

export type MaskOptions = {
    precision?: number;
    decimalSeparator?: string;
    thousandSeparator?: string;
    allowEmpty?: boolean;
    allowNegative?: boolean;
};

export class Mask {
    public mask: string;
    public float: number;

    constructor(
        public readonly value: MaskValue,
        private readonly options: MaskOptions = defaultOptions,
    ) {
        this.value = value;

        if (typeof value === 'string') {
            const mask = this.string(value);
            this.mask = mask.mask;
            this.float = mask.float;
        } else {
            const mask = this.number(value);
            this.mask = mask;
            this.float = value;
        }
    }

    private executeRulesRawString(rawValue: string, float: number) {
        const isNegative = rawValue.includes('-');
        if (isNegative && this.options.allowNegative) return -float;
        return float;
    }

    private executeRulesOnNumber(locale: string, float: number) {
        let localeString = locale;

        const isNegative = locale.includes('-');

        if (isNegative && !this.options.allowNegative) {
            localeString = localeString.replace('-', '');
        }

        if (this.options.decimalSeparator) {
            localeString = localeString.replace(
                ',',
                this.options.decimalSeparator || DECIMAL_SEPARATOR,
            );
        }

        if (this.options.thousandSeparator) {
            localeString = localeString.replace(
                '.',
                this.options.thousandSeparator || THOUSAND_SEPARATOR,
            );
        }

        if (this.options.allowEmpty && float === 0) {
            localeString = '';
        }
        return localeString;
    }

    private sanatizeString(value: string): string {
        return value.replace(/\D/g, '');
    }

    private parseNumber(value: string): number {
        const sanitizedValue = this.sanatizeString(value);
        return (
            parseFloat(sanitizedValue) /
                Math.pow(10, this.options.precision || PRECISION) || 0
        );
    }

    private unmaskToNumber(value: string): number {
        const float = this.parseNumber(value);
        return this.executeRulesRawString(value, float);
    }

    private generateLocale(float: number): string {
        return float.toLocaleString('pt-BR', {
            minimumFractionDigits: this.options.precision,
            maximumFractionDigits: this.options.precision,
            currency: 'BRL',
        });
    }

    private number(float: number): string {
        const locale = this.generateLocale(float);
        return this.executeRulesOnNumber(locale, float);
    }

    private string(value: string) {
        const float = this.unmaskToNumber(value);
        const mask = this.number(float);
        return { value, float, mask };
    }
}
