import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { snakeCaseToCamelCase } from '../../utils/snakeCaseToCamelCase';
import { Scenario } from './outputArea';

export type OutputSavedSimulationProps = {
    modal: {
        isEditing: boolean;
        showModalSave: boolean;
        showModalUpdate: boolean;
        showModalConfirmAction: boolean;
    };
    scenario: Scenario;
};
const initialState: OutputSavedSimulationProps = {
    modal: {
        isEditing: false,
        showModalSave: false,
        showModalUpdate: false,
        showModalConfirmAction: false,
    },
    scenario: {
        id: null,
        client: null,
        scenarioName: null,
        user: null,
        date: null,
        username: null,
    },
};

const slice = createSlice({
    name: 'demand-forecast-output-saved-simulations',
    initialState,
    reducers: {
        handleModal(
            state,
            {
                payload,
            }: PayloadAction<{
                modalType:
                    | 'showModalSave'
                    | 'showModalUpdate'
                    | 'showModalConfirmAction';
                open: boolean;
            }>,
        ) {
            state.modal[payload.modalType] = payload.open;
        },
        setScenario(state, { payload }: PayloadAction<Partial<Scenario>>) {
            state.scenario = {
                ...state.scenario,
                ...snakeCaseToCamelCase(payload),
            };
        },
        clearOutputSavedSimulationData: () => initialState,
    },
});

export const { clearOutputSavedSimulationData, handleModal, setScenario } =
    slice.actions;

export default slice.reducer;
