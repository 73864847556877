import React from 'react'
import ProductSlider from '../ProductSlider'
import AllProductsSelected from '../AllProductsSelected'
import AllProductsSelectedTable from '../AllProductsSelectedTable'

type PanelProductSliderArea = {
    showPainel: boolean,
    showWarningToMuchProducts: boolean,
    showMoreProductsSelected: boolean,
    showWarningNoGtin : boolean,
    loadingProductDashboard: boolean,
    loadingProductDashboardTable: boolean,
    productDashboardList: any,
    productDashboardTableList: any,
    canDownload: boolean,
    onClickSeeAll: () => void,
    onClickSeeLess: () => void,
    onClickDownload: () => void,
    onClickTablePage: () => void,
}

export const PanelProductSliderArea = ({
    showPainel,
    showWarningToMuchProducts,
    showMoreProductsSelected,
    showWarningNoGtin,
    loadingProductDashboard,
    loadingProductDashboardTable,
    productDashboardList,
    productDashboardTableList,
    canDownload,
    onClickSeeAll,
    onClickSeeLess,
    onClickDownload,
    onClickTablePage
}: PanelProductSliderArea) => {
    return (
        <>
            {showPainel &&
                !showWarningToMuchProducts &&
                !showMoreProductsSelected &&
                !showWarningNoGtin ? (
                    <ProductSlider
                        loading={loadingProductDashboard}
                        products={productDashboardList?.datapoints}
                        onClickSeeAll={onClickSeeAll}
                    />
                ) : null}
                {showPainel &&
                !showWarningToMuchProducts &&
                showMoreProductsSelected &&
                !showWarningNoGtin ? (
                    <AllProductsSelected
                        loading={loadingProductDashboardTable}
                        canDownload={canDownload}
                        selectedQuantity={
                            productDashboardTableList?.totalElements
                        }
                        onClickSeeLess={onClickSeeLess}
                        onClickDownload={onClickDownload}
                    >
                        <AllProductsSelectedTable
                            products={productDashboardTableList}
                            onClickTablePage={onClickTablePage}
                        />
                    </AllProductsSelected>
                ) : null}
        </>
    )
}
