import React from 'react';

import { IoMdTrash } from 'react-icons/io';
import { MdDownload } from 'react-icons/md';

import { Button } from 'rsuite';
import { useHandleScenarios } from '../../../hooks/useHandleScenarios';
import { ModalConfirmAction } from '../../ModalConfirmAction';
import styles from './ResultAndActionArea.module.scss';
export const ResultAndActionArea = () => {
    const {
        handleShowConfirmActionModal,
        closeAllModals,
        handleDeleteScenario,
        handleDownloadSelectedScenarios,
        props,
    } = useHandleScenarios();

    const { disabled, disabledMainButton, simulationsCount, modal } = props;

    const handleCompare = () => {
        console.log('compare');
    };

    return (
        <main className={styles.container}>
            {simulationsCount > 1 ? (
                <p>
                    Foram encontrados{' '}
                    <strong>{simulationsCount} resultados</strong> no total.
                </p>
            ) : (
                <p>
                    Foi encontrado <strong>{simulationsCount} resultado</strong>{' '}
                    no total.
                </p>
            )}

            <div className={styles.buttons}>
                <Button
                    disabled={disabled}
                    className={styles['icon-button']}
                    onClick={handleShowConfirmActionModal}
                >
                    <IoMdTrash />
                </Button>
                <Button
                    disabled={disabled}
                    className={styles['icon-button']}
                    onClick={handleDownloadSelectedScenarios}
                >
                    <MdDownload />
                </Button>

                {/* <Button
                    disabled={disabledMainButton}
                    appearance="primary"
                    className={styles['main-button']}
                    onClick={handleCompare}
                >
                    Comparar
                </Button> */}
            </div>
            {modal.showModalConfirmAction && (
                <ModalConfirmAction
                    show={modal.showModalConfirmAction}
                    onClickAction={handleDeleteScenario}
                    onHide={closeAllModals}
                />
            )}
        </main>
    );
};
