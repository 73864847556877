import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { SetupFarmaCallout, SetupFarmaFooter, SetupFarmaHeader, SetupPMCConfiguration } from "./components";
import { SetupFarmaFormValues, setupFarmaSchema } from "./hooks/useFarma";

export const SetupFarma = () => {
    const formMethodsIpaFarma = useForm<SetupFarmaFormValues>({
        resolver: zodResolver(setupFarmaSchema),
        defaultValues: {
            usePMCForIPAPricing: false,
        },
    });

    return (
        <>
            <FormProvider {...formMethodsIpaFarma}>
                <SetupFarmaHeader />
                <SetupPMCConfiguration />
                <SetupFarmaCallout />
                <SetupFarmaFooter />
            </FormProvider>

        </>
    );
};
