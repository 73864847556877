

import React from 'react';

interface CellWrapperProps {
    children: React.ReactNode;
    className?: string;
}
export function CellWrapper({ children, className }: CellWrapperProps) {
    return <div className={className}>{children}</div>;
}
