import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GerenciadorPrecos } from '../../../@types/GerenciadorPrecos';
import { RootState } from '../../../@types/RootState';

type Keys = GerenciadorPrecos.FilterKeys;

export type FiltersSearchState = Partial<Record<Keys, string>>;

const initialFiltersSearchState: FiltersSearchState = {
    productIds: '',
    productFamilyIds: '',
    storeIds: '',
    productBrand: '',
    supplier: '',
    categoryLevel1: '',
    categoryLevel2: '',
    categoryLevel3: '',
    categoryLevel4: '',
    categoryLevel5: '',
    categoryLevel6: '',
    categoryLevel7: '',
};

const slice = createSlice({
    name: 'ipa.filters.search',
    initialState: initialFiltersSearchState,
    reducers: {
        RESET_FILTERS_SEARCH: () => initialFiltersSearchState,
        SET_FILTERS_SEARCH_BY_KEY: (
            state,
            { payload }: PayloadAction<{ key: Keys; query: string }>,
        ) => ({
            ...state,
            [payload.key]: payload.query,
        }),
        RESET_FILTERS_SEARCH_BY_KEY: (
            state,
            { payload }: PayloadAction<{ key: Keys }>,
        ) => ({
            ...state,
            [payload.key]: '',
        }),
    },
});

const selectorFiltersSearch = (state: RootState) => {
    return state.gerenciadorPrecosReducer.filters.search;
};

const {
    RESET_FILTERS_SEARCH,
    SET_FILTERS_SEARCH_BY_KEY,
    RESET_FILTERS_SEARCH_BY_KEY,
} = slice.actions;

export {
    RESET_FILTERS_SEARCH,
    RESET_FILTERS_SEARCH_BY_KEY,
    SET_FILTERS_SEARCH_BY_KEY,
    initialFiltersSearchState,
    selectorFiltersSearch,
};

export default slice.reducer;
