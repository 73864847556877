import React from 'react';
import styles from '../SetupIPADadosDeConcorrencia.module.scss';

export const FormHeader = () => (
    <div className={styles['setup-ipa__main-config']}>
        <h3>Dados de concorrência</h3>
        <p className={styles['setup-ipa__main-config__header__subtitle']}>
            Defina as configurações sobre o uso dos dados de concorrência
        </p>
    </div>
);
