import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { snakeCaseToCamelCase } from '../../utils/snakeCaseToCamelCase';
import { Input } from './inputArea';
import { OutputSimulation, Scenario } from './outputArea';

export type Query = {
    fromDate: string;
    newPrice: number;
    productId: string;
    productName: string;
    storeIds: string[];
    toDate: string;
};
export type ScenariosProps = {
    simulations: Simulation[];
    output: OutputSimulation;
    query: Query;
    input: Input;
    scenario: Scenario;
    checked: boolean;
};

type ScenariosChecked = {
    id: string;
    name: string;
};

export type PaginationScenarioProps = {
    number: number;
    size: number;
    totalElements: number;
    hasContent: boolean;
    first: boolean;
    last: boolean;
    numberOfElements: number;
    totalPages: number;
    activePage: number;
    pageable: Pageable;
    empty: boolean;
};

export type Pageable = {
    sort: any[];
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
};

type simulationsProps = {
    list: ScenariosProps[];
    cache: ScenariosProps[];
};

type Simulation = {
    query: Query;
    output: OutputSimulation;
};

export type DemandForecastSimulationsProps = {
    savedScenarios: simulationsProps;
    pagination: PaginationScenarioProps;
    // queryFilter: QueryFilter;
    calculations: {
        hasSimulation: boolean;
        isSearchDone: boolean;
        allChecked: boolean;
        hasSomeChecked: boolean;
    };
    scenariosChecked: ScenariosChecked[];
};

const initialState: DemandForecastSimulationsProps = {
    savedScenarios: {
        list: [],
        cache: [],
    },

    pagination: {
        number: 0,
        size: 0,
        totalElements: 0,
        hasContent: false,
        first: false,
        last: false,
        numberOfElements: 0,
        totalPages: 0,
        activePage: 0,
        pageable: {
            sort: [],
            offset: 0,
            pageNumber: 0,
            pageSize: 0,
            paged: false,
            unpaged: false,
        },
        empty: false,
    },
    calculations: {
        hasSimulation: false,
        isSearchDone: false,
        allChecked: false,
        hasSomeChecked: false,
    },
    scenariosChecked: [],
};

const setScenariosChecked = (
    state: DemandForecastSimulationsProps,
    simulation: ScenariosProps[],
) => {
    state.calculations.hasSomeChecked = simulation.some(
        (scenario) => scenario.checked,
    );

    state.scenariosChecked = simulation
        .filter((scenario) => scenario.checked)
        .map((s) => {
            return {
                id: s.scenario.id || '',
                name: s.scenario.scenarioName || '',
            };
        });
};

const slice = createSlice({
    name: 'demand-forecast-saved-scenarios',
    initialState,
    reducers: {
        setSavedScenarios(
            state,
            {
                payload,
            }: PayloadAction<{
                data: Omit<ScenariosProps, 'checked'>[];
                pagination: PaginationScenarioProps;
            }>,
        ) {
            const data = snakeCaseToCamelCase(payload.data);

            const dataMapped = data?.map((output) => {
                const isChecked = state.scenariosChecked.some(
                    (sc) => sc.id === output.scenario.id,
                );

                return {
                    ...output,
                    checked: isChecked,
                };
            });

            state.savedScenarios.list = dataMapped;
            state.savedScenarios.cache = dataMapped;
            state.pagination = snakeCaseToCamelCase(payload.pagination);
        },
        checkSavedScenarios(
            state,
            {
                payload,
            }: PayloadAction<{
                index: number;
                checked: boolean;
                rowData: Omit<ScenariosProps, 'checked'>;
            }>,
        ) {
            const simulation = state.savedScenarios.list.map(
                (output, index) => {
                    if (index === payload.index) {
                        return {
                            ...output,
                            checked: payload.checked,
                        };
                    }
                    return output;
                },
            );

            state.calculations.allChecked = simulation.every(
                (scenario) => scenario.checked,
            );

            setScenariosChecked(state, simulation);

            state.savedScenarios.list = simulation;
        },
        checkAllSavedScenarios(
            state,
            { payload }: PayloadAction<{ checked: boolean }>,
        ) {
            const simulation = state.savedScenarios.list.map((output) => {
                return {
                    ...output,
                    checked: payload.checked,
                };
            });

            setScenariosChecked(state, simulation);

            state.savedScenarios.list = simulation;
            state.calculations.allChecked = payload.checked;
        },

        clearSavedScenarios: () => initialState,
    },
});

export const {
    setSavedScenarios,
    clearSavedScenarios,
    checkSavedScenarios,
    checkAllSavedScenarios,
} = slice.actions;

export default slice.reducer;
