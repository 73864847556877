import { trackPromise } from 'react-promise-tracker';
import api from '../../../../../utils/API';
import {
    CompetitorDaysData,
    SetupIPADadosDeConcorrencia,
} from './SetupIPADadosDeConcorrenciatypes';

export async function getSetupIPADadosDeConcorrencia(): Promise<SetupIPADadosDeConcorrencia> {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/setup/basic-config`,
            method: 'GET',
            expectToken: true,
        }).then((resp) => resp.data),
        'ipa/setup-form/competitor-days',
    );
}

export async function postSetupIPADadosDeConcorrencia(
    data: CompetitorDaysData,
): Promise<SetupIPADadosDeConcorrencia> {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/setup/basic-config`,
            method: 'POST',
            expectToken: true,
            data,
        }).then((resp) => resp.data),
        'ipa/setup-form/competitor-days',
    );
}

export async function upadateSetupIPADadosDeConcorrencia(
    data: CompetitorDaysData,
): Promise<SetupIPADadosDeConcorrencia> {
    return trackPromise(
        api({
            url: `${process.env.REACT_APP_IPA_URL}/setup/basic-config`,
            method: 'PUT',
            expectToken: true,
            data,
        }).then((resp) => resp.data),
        'ipa/setup-form/competitor-days',
    );
}
